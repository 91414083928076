import { Link, createFileRoute } from "@tanstack/react-router";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { Button } from "@dashboard/common/ui/button";
import { useForm, useFieldArray } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import { Switch } from "@dashboard/common/ui/switch";
import { FlowForm, flowFormSchema } from "../-utils/flowFormSchema";
import { Separator } from "@dashboard/common/ui/separator";
import {
  SelectRoot as Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@dashboard/common/ui/select";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useDebounce } from "@dashboard/utils/hooks";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/flows/$flowId/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug, flowId },
  }) => {
    await Promise.all([
      trpcClientUtils.dashboard.flows.get.ensureData({
        businessSlug,
        id: flowId,
      }),
      trpcClientUtils.dashboard.flows.getAllActions.ensureData({
        businessSlug,
      }),
    ]);
  },
  component: FlowPage,
});

function FlowPage() {
  const { organizationSlug, businessSlug, flowId } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [actions] = trpc.dashboard.flows.getAllActions.useSuspenseQuery({
    businessSlug,
  });

  const [flow] = trpc.dashboard.flows.get.useSuspenseQuery({
    businessSlug,
    id: flowId,
  });

  const defaultValues: FlowForm = { ...flow, id: flowId };

  const form = useForm<FlowForm>({
    resolver: zodResolver(flowFormSchema),
    defaultValues,
  });

  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: "variants",
  });

  const variantTitles = useWatch({
    control: form.control,
    name: "variants",
    defaultValue: fields,
  });

  const debouncedUpdate = useDebounce((index: number, value: string) => {
    if (value === "") {
      value = "Untitled";
    }
    update(index, { ...fields[index], title: value });
  }, 2000);

  useEffect(() => {
    for (const [index, variant] of variantTitles.entries()) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (variant && fields[index] && variant.title !== fields[index].title) {
        debouncedUpdate(index, variant.title);
      }
    }
  }, [variantTitles, fields, debouncedUpdate]);

  const { onSubmit, onError } = useSubmitForm();

  const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);

  useEffect(() => {
    if (fields.length > 0) {
      setSelectedVariantIndex(fields.length - 1);
    }
  }, [fields.length]);

  if (fields.length === 0) {
    throw new Error("At least one variant is required");
  }

  return (
    <Form {...form}>
      <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
        <div className="relative py-0">
          <div className="border-gray-light flex w-full justify-between border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">{flow.title}</h1>
            <div className="flex items-center gap-2">
              <Link
                to="/organization/$organizationSlug/settings/$businessSlug/flows"
                params={{ organizationSlug, businessSlug }}
              >
                <Button
                  variant="secondary"
                  className="flex items-center"
                  type="button"
                >
                  Back to flows
                </Button>
              </Link>

              <Button
                variant="publish"
                className="flex items-center"
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>

          <div className="flex gap-10 px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Flow Title</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      Give a unique and descriptive title to the flow, e.g.
                      &quot;Order Tracking&quot;, &quot;Quality
                      Complaints&quot;, etc.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="whenToFollow"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>When should this flow be followed?</FormLabel>
                    <FormControl>
                      <Textarea rows={2} {...field} />
                    </FormControl>
                    <FormDescription>
                      Provide a description of when this flow should be
                      followed. You may provide example scenarios and customer
                      inquiries.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex items-center gap-4">
                {fields.length > 1 && (
                  <Select
                    value={selectedVariantIndex.toString()}
                    onValueChange={(value) =>
                      setSelectedVariantIndex(Number(value))
                    }
                  >
                    <SelectTrigger className="w-auto">
                      <SelectValue placeholder="Select a variant" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Variants</SelectLabel>
                        {fields.map((field, index) => (
                          <SelectItem key={field.id} value={index.toString()}>
                            {field.title}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                )}

                <Button
                  variant="secondary"
                  type="button"
                  onClick={() =>
                    append({
                      title: "New variant",
                      whenToFollow: "",
                      botInstructions: "",
                    })
                  }
                >
                  Add variant
                </Button>
              </div>

              {fields[selectedVariantIndex] && (
                <div
                  key={fields[selectedVariantIndex].id}
                  className="flex flex-col gap-4"
                >
                  {fields.length > 1 && (
                    <FormField
                      control={form.control}
                      name={`variants.${selectedVariantIndex}.title` as const}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Variant Title</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  )}

                  <FormField
                    control={form.control}
                    name={
                      `variants.${selectedVariantIndex}.whenToFollow` as const
                    }
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          When should this variant be followed?
                        </FormLabel>
                        <FormControl>
                          <Textarea rows={2} {...field} />
                        </FormControl>
                        <FormDescription>
                          Provide a description of when this variant should be
                          followed. You may provide example scenarios and
                          customer inquiries.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name={
                      `variants.${selectedVariantIndex}.botInstructions` as const
                    }
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Bot Instructions</FormLabel>
                        <FormControl>
                          <Textarea rows={20} {...field} />
                        </FormControl>
                      </FormItem>
                    )}
                  />

                  {fields.length > 1 && (
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={() => remove(selectedVariantIndex)}
                    >
                      Remove this variant
                    </Button>
                  )}
                </div>
              )}

              <FormField
                control={form.control}
                name="isLive"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel>Live Flow</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="orderIdRequired"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel>Order ID Required</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="border-gray-light flex max-w-96 flex-col items-start gap-4 rounded-lg border p-6">
              <p>
                The bot can carry out a large number of actions during the flow,
                to tell the bot that it should carry out an action include this
                instruction:
              </p>
              <p>
                call the <span className="font-medium">`botAction`</span>{" "}
                function with <span className="font-medium">argument1</span>,{" "}
                <span className="font-medium">argument2</span>... as the
                arguments
              </p>
              <Separator />
              <p className="font-medium">Available actions:</p>
              <ul>
                {actions.map((action) => (
                  <li
                    className="m-1 inline-block rounded-lg bg-[#DCE1F9] p-1 px-2 text-sm font-medium text-[#334AFA]"
                    key={action}
                  >
                    {action}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
