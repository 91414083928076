import { PlusIcon, Search, X } from "lucide-react";
import { Route } from "../_conversationSidebar";
import { TooltipButton } from "@dashboard/common/TooltipButton/TooltipButton";

interface SidebarHeaderProps {
  searchActive: boolean;
  setSearchActive: (active: boolean) => void;
}

export function SidebarHeader({
  searchActive,
  setSearchActive,
}: SidebarHeaderProps) {
  const navigate = Route.useNavigate();
  const search = Route.useSearch();
  const { organizationSlug } = Route.useParams();
  const { hasExternalTicketConfig } = Route.useRouteContext();

  const setSearch = () => {
    const newSearchActive = !searchActive;
    setSearchActive(newSearchActive);

    const newView = newSearchActive ? "all" : undefined;

    void navigate({
      search: {
        view: newView,
        searchQuery: undefined,
        filters: undefined,
        status: undefined,
        sortBy: undefined,
        tutorial: undefined,
        businessSlug: undefined,
      },
    });
  };
  const newConversation = () => {
    void navigate({
      to: "/organization/$organizationSlug/conversation/new",
      params: { organizationSlug },
      search,
    });
  };

  return (
    <div className="flex items-center justify-between">
      <h1 className="text-xl font-semibold">Help Desk</h1>
      <div className="flex items-center">
        {!hasExternalTicketConfig && (
          <TooltipButton
            variant="ghost"
            onClick={newConversation}
            buttonClassName="hover:text-blue hover:bg-blue-light h-auto w-auto p-1 text-black"
            tooltipContent="Create a new conversation"
          >
            <PlusIcon strokeWidth={2} className="h-5 w-5" />
          </TooltipButton>
        )}

        <TooltipButton
          variant="ghost"
          onClick={setSearch}
          buttonClassName="hover:text-blue hover:bg-blue-light h-auto w-auto p-1 text-black"
          tooltipContent="Search conversations"
        >
          {searchActive ? (
            <X strokeWidth={2} className="h-5 w-5" />
          ) : (
            <Search strokeWidth={2} className="h-5 w-5" />
          )}
        </TooltipButton>
      </div>
    </div>
  );
}
