import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { MacroForm, macroFormSchema } from "../-utils/macroFormSchema";
import { useCreateMacro } from "../-hooks/useCreateMacro";
import { MacroFormBody } from "./MacroFormBody";
import { toast } from "sonner";
import { formatPayload } from "../-utils/formatPayload";

export function CreateMacroForm({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) {
  const form = useForm<MacroForm>({
    resolver: zodResolver(macroFormSchema),
    defaultValues: {
      title: "",
      content: null,
      availableFor: "",
      actions: [],
    },
  });

  const { onSubmit: onSubmitMutation } = useCreateMacro({ setOpen });

  const onSubmit = (data: MacroForm) => {
    const payload = formatPayload(data);

    onSubmitMutation(payload);
  };

  const onError = (errors: unknown) => {
    console.error(errors);
    toast.error("Failed to create macro");
  };

  return <MacroFormBody form={form} onSubmit={onSubmit} onError={onError} />;
}
