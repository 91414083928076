import { Button } from "@dashboard/common/ui/button";
import { DialogFooter } from "@dashboard/common/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { UseFormReturn } from "react-hook-form";
import { ArticleForm } from "../-utils/articleForm";

interface ArticleFormBodyProps {
  form: UseFormReturn<ArticleForm>;
  onSubmit: (data: ArticleForm) => void;
}

export function ArticleFormBody({ form, onSubmit }: ArticleFormBodyProps) {
  return (
    <Form {...form}>
      <form
        onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input placeholder="Do you ship to France?" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="content"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Content</FormLabel>
              <FormControl>
                <Textarea
                  rows={8}
                  placeholder="Yes, we do ship to France! We are pleased to offer international shipping options to our customers in France."
                  {...field}
                />
              </FormControl>
              <FormDescription>
                The content and title can be in any language.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter className="lg:space-x-2.5">
          <Button variant="publish" type="submit">
            Save
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
