import { Button } from "@dashboard/common/ui/button";
import { applyMacro } from "../../-utils/applyMacro";
import { Route } from "..";
import { Macro, MacroAction } from "@be/modules/macros/macros.types";
import { Plus } from "lucide-react";
import { useState, useEffect } from "react";
import { useConversation } from "../-hooks/useConversation.hook";
import { useLogMacroRecommendationUsed } from "../-hooks/useLogMacroRecommendationUsed";
import { useMacroData } from "../-hooks/useMacroData";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@dashboard/common/ui/hover-card";
import { MacroPreview } from "../../-components/Macros/MacroPreview";

export function MacroRecommendations({
  setInput,
  setActions,
}: {
  setInput: (input: string) => void;
  setActions: (actions: MacroAction[]) => void;
}) {
  const [recommendedMacros, setRecommendedMacros] = useState<Macro[]>([]);
  const { trpc } = Route.useRouteContext();
  const { publicId, organizationSlug } = Route.useParams();

  const macroData = useMacroData();
  const conversation = useConversation();
  const logMacroRecommendationUsed = useLogMacroRecommendationUsed();

  const { data, isLoading, refetch } =
    trpc.dashboard.macros.getRecommendations.useQuery({
      publicId,
      organizationSlug,
    });

  useEffect(() => {
    void refetch();
  }, [conversation]);

  useEffect(() => {
    if (data) {
      setRecommendedMacros(data);
    }
  }, [data]);

  if (isLoading || recommendedMacros.length === 0) {
    return null;
  }

  const handleMacroClick = (macro: Macro) => {
    applyMacro({
      macro,
      macroData,
      setInput,
      setActions,
    });

    logMacroRecommendationUsed({ macroTitle: macro.title });
    setRecommendedMacros([]);
  };

  return (
    <div className="flex flex-row flex-wrap items-center justify-start gap-2 pt-2">
      {recommendedMacros.map((macro) => (
        <HoverCard key={macro.id}>
          <HoverCardTrigger asChild>
            <Button
              variant="secondary"
              className="bg-accent h-auto rounded-lg px-2 py-1.5 text-xs font-normal"
              onClick={() => handleMacroClick(macro)}
            >
              <Plus className="mr-2 h-4 w-4" strokeWidth={1.5} />
              {macro.title}
            </Button>
          </HoverCardTrigger>
          <HoverCardContent className="w-96 p-0">
            <MacroPreview macro={macro} />
          </HoverCardContent>
        </HoverCard>
      ))}
    </div>
  );
}
