import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/presta.svg";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        PrestaShop Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Optimize your eCommerce operations by integrating your PrestaShop store
        with Octocom. This powerful integration allows you to automate and
        manage your PrestaShop orders, customer interactions, and store
        analytics, ensuring a more efficient and seamless shopping experience
        for your customers.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Seamless Integration:</span>{" "}
          Effortlessly connect your PrestaShop store to Octocom, enabling you to
          manage all aspects of your eCommerce business from a single,
          centralized platform.
        </li>
        <li>
          <span className="font-medium">Automated Order Management:</span>{" "}
          Streamline your order processing by automating key tasks such as order
          confirmations, shipment tracking, and inventory updates. This reduces
          manual workload and increases the accuracy of your order fulfillment
          process.
        </li>
        <li>
          <span className="font-medium">Unified Dashboard:</span> Monitor and
          manage all your PrestaShop store activities from one easy-to-use
          dashboard. Track orders, customer inquiries, and store performance
          metrics without needing to switch between different tools.
        </li>
        <li>
          <span className="font-medium">Integrated Customer Support:</span>{" "}
          Leverage Octocom&apos;s support tools to handle customer inquiries
          related to your PrestaShop store. Respond to questions, resolve
          issues, and manage returns directly from the Octocom dashboard,
          ensuring a smooth and responsive customer service experience.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers you to manage your PrestaShop store more
        effectively, offering both automation and direct management tools to
        enhance your eCommerce operations and elevate customer satisfaction.
      </p>
    </div>
  );
}

export function PrestaIntegrationDialog({ installed }: { installed: boolean }) {
  return (
    <>
      <IntegrationBlock
        title="PrestaShop"
        shortDescription="Automate the customer support for your PrestaShop store with Octocom."
        longDescription={<Description />}
        imageSrc={icon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
