import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/klaviyo.png";

import { Separator } from "@dashboard/common/ui/separator";
import { KlaviyoSettingsForm } from "./KlaviyoSettingsForm";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Klaviyo Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Streamline your email marketing efforts by integrating Klaviyo with
        Octocom. This integration enables automatic updates to your Klaviyo
        newsletter subscriptions whenever a customer makes a request, ensuring
        that your subscriber lists are always accurate and up-to-date.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">
            Automatic Subscription Management:
          </span>{" "}
          Effortlessly update Klaviyo newsletter subscriptions based on customer
          preferences captured through Octocom. This ensures that your email
          lists reflect your customers&apos; most current requests without
          manual adjustments.
        </li>
        <li>
          <span className="font-medium">Enhanced Efficiency:</span> Minimize the
          workload associated with managing subscription requests, allowing your
          marketing team to focus on creating impactful campaigns that reach the
          right audience.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration simplifies the process of managing your Klaviyo
        newsletter subscriptions by automatically updating them according to
        customer requests, ensuring your email marketing efforts are efficient
        and aligned with your audience’s preferences.
      </p>
    </div>
  );
}

export function KlaviyoIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        title="Klaviyo"
        shortDescription="Automate your Klaviyo newsletter subscriptions with Octocom."
        longDescription={<Description />}
        imageSrc={icon}
        installed={installed}
        settingsForm={<KlaviyoSettingsForm />}
      />
    </>
  );
}
