import { env } from "@dashboard/env";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import { redirect, notFound, ToOptions } from "@tanstack/react-router";

export async function createAuthClient() {
  return await createAuth0Client({
    domain: env.DASHBOARD_AUTH0_DOMAIN,
    clientId: env.DASHBOARD_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: env.DASHBOARD_AUTH0_AUDIENCE,
    },
    cacheLocation: "localstorage",
  });
}

export async function checkAuth({
  redirectToOnFail,
}: {
  redirectToOnFail?: ToOptions;
}) {
  const auth0 = await createAuthClient();
  try {
    await auth0.getTokenSilently();
  } catch {
    throw redirect(redirectToOnFail ?? { to: "/organization" });
  }
}

export async function authenticate({
  auth0CallbackCode,
}: {
  auth0CallbackCode?: string;
}) {
  const auth0 = await createAuthClient();
  if (auth0CallbackCode) {
    await auth0.handleRedirectCallback();
    throw redirect({
      to: "/",
    });
  }
  try {
    await auth0.getTokenSilently();
    return {
      logout: async () => await auth0.logout(),
    };
  } catch (e) {
    await auth0.loginWithRedirect();
    // sleep for 1 sec because the redirect does not happen instantly after the
    // promise resolves
    await new Promise((resolve) => setTimeout(resolve, 1000));
    throw notFound();
  }
}

export async function getToken() {
  const auth0 = await createAuthClient();
  try {
    return await auth0.getTokenSilently();
  } catch (e) {
    return;
  }
}
