import { IntegrationBlock } from "./IntegrationBlock";
import zendeskIcon from "@dashboard/assets/zendesk.svg";
import { Button } from "@dashboard/common/ui/button";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Zendesk Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Enhance your customer support capabilities by integrating Zendesk with
        Octocom. This integration allows you to automate and manage your Zendesk
        tickets, streamlining your support operations and ensuring that your
        team delivers prompt and effective assistance across all customer
        channels.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Effortless Integration:</span> Connect
          your Zendesk account to Octocom with ease, enabling centralized
          management of your customer support tickets and interactions from a
          single platform.
        </li>
        <li>
          <span className="font-medium">Automated Ticket Management:</span> Take
          advantage of Octocom&apos;s automation features to efficiently manage
          your Zendesk tickets. Automatically categorize, prioritize, and assign
          tickets to the right team members, ensuring that customer issues are
          resolved quickly and accurately.
        </li>
        <li>
          <span className="font-medium">Unified Support Dashboard:</span> Manage
          all your Zendesk tickets within the Octocom dashboard. This
          centralized approach allows your team to handle customer inquiries
          across different channels without the need to switch between
          platforms, improving response times and operational efficiency.
        </li>
        <li>
          <span className="font-medium">Enhanced Customer Communication:</span>{" "}
          Leverage Octocom to deliver personalized and proactive support through
          Zendesk. Ensure that every customer interaction is timely, relevant,
          and handled with care, enhancing customer satisfaction and loyalty.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers your support team to manage Zendesk tickets
        more effectively, providing automation and centralized management tools
        to improve your customer service operations and achieve better outcomes.
      </p>
    </div>
  );
}

export function ZendeskIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        title="Zendesk"
        shortDescription="Pass unhandled customer support queries to Zendesk."
        longDescription={<Description />}
        imageSrc={zendeskIcon}
        manualInstallation={false}
        customButton={
          <Button
            type="button"
            variant="publish"
            className="w-40"
            onClick={() =>
              window.open(
                "https://www.zendesk.co.uk/marketplace/apps/support/1057731/octocom/",
                "_blank",
              )
            }
          >
            Install
          </Button>
        }
        installed={installed}
      />
    </>
  );
}
