import { SidebarButton } from "@dashboard/common/Sidebar/SidebarButton";
import { Bot, ScanLine, SquareUser, WalletCards } from "lucide-react";

import { ViewAccordion } from "./ViewAccordion";

import { Route } from "../_conversationSidebar";
import { BusinessSelect } from "./BusinessSelect";

export function SidebarViews() {
  const search = Route.useSearch();
  const { organizationSlug } = Route.useParams();
  const { trpc, hasExternalTicketConfig } = Route.useRouteContext();

  const [yourInboxCount] =
    trpc.dashboard.conversations.getCount.useSuspenseQuery({
      organizationSlug,
      view: "your-inbox",
      filters: { businessId: search.filters?.businessId },
      status: "open",
    });

  const [unassignedCount] =
    trpc.dashboard.conversations.getCount.useSuspenseQuery({
      organizationSlug,
      view: "unassigned",
      filters: { businessId: search.filters?.businessId },
      status: "open",
    });

  const [handedOffCount] =
    trpc.dashboard.conversations.getCount.useSuspenseQuery({
      organizationSlug,
      view: "handed-off",
      filters: { businessId: search.filters?.businessId },
      status: "open",
    });

  const [botCount] = trpc.dashboard.conversations.getCount.useSuspenseQuery({
    organizationSlug,
    view: "bot",
    filters: { businessId: search.filters?.businessId },
    status: "open",
  });

  const filteredSearch: { filters?: { businessId?: string } } = {};

  if (search.filters?.businessId) {
    filteredSearch.filters = { businessId: search.filters.businessId };
  }

  return (
    <>
      <BusinessSelect />

      {!hasExternalTicketConfig && (
        <>
          <SidebarButton
            text="Your Inbox"
            to="/organization/$organizationSlug/conversation"
            active={search.view === "your-inbox"}
            search={{
              ...filteredSearch,
              view: "your-inbox",
              status: "open",
            }}
            icon={SquareUser}
            count={yourInboxCount}
          />
          <SidebarButton
            text="Unassigned"
            to="/organization/$organizationSlug/conversation"
            active={search.view === "unassigned"}
            search={{
              ...filteredSearch,
              view: "unassigned",
              status: "open",
            }}
            icon={ScanLine}
            count={unassignedCount}
          />
        </>
      )}
      <SidebarButton
        text="Human"
        to="/organization/$organizationSlug/conversation"
        active={search.view === "handed-off"}
        search={{
          ...filteredSearch,
          view: "handed-off",
          status: hasExternalTicketConfig ? "closed" : "open",
        }}
        icon={WalletCards}
        count={hasExternalTicketConfig ? undefined : handedOffCount}
      />
      <SidebarButton
        text="Bot"
        to="/organization/$organizationSlug/conversation"
        active={search.view === "bot"}
        search={{
          ...filteredSearch,
          view: "bot",
          status: undefined,
        }}
        icon={Bot}
        count={hasExternalTicketConfig ? undefined : botCount}
      />

      <ViewAccordion />
    </>
  );
}
