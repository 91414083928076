import { z } from "zod";

export const orderCancelReasonSchema = z.enum([
  "CUSTOMER",
  "DECLINED",
  "FRAUD",
  "INVENTORY",
  "OTHER",
  "STAFF",
]);

export const cancelationDataSchema = z.object({
  reason: orderCancelReasonSchema,
  refund: z.boolean(),
  restock: z.boolean(),
  notifyCustomer: z.boolean(),
  staffNote: z.string().nullish(),
});
