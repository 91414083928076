import { FormImageKey, WebChatConfigForm } from "../-utils/formSchema";
import { WebChatConfigUpdate } from "@be/modules/channels/webChat/webChat.types";
import { Route } from "..";
import { toast } from "sonner";

export function useSubmitForm() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const trpcUtils = trpc.useUtils();

  const updateMutation = trpc.dashboard.webChat.updateConfig.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.webChat.getConfig.invalidate({ businessSlug });

      toast("Successfully updated web chat settings.");
    },
    onError: () => {
      toast("Failed to update the web chat settings. Please try again.");
    },
  });

  const imageUploadMutation =
    trpc.dashboard.webChat.getImageUploadUrl.useMutation({
      onError: () => {
        toast("Update failed: image upload error. Please try again.");
      },
    });

  return {
    onSubmit: async (data: WebChatConfigForm) => {
      const payloadData = {
        ...data,
        topBarImage: undefined,
        botIcon: undefined,
        launcherIcon: undefined,
        launcherImage: undefined,
        attentionGrabberSuggestions: undefined,
      };

      const payload: WebChatConfigUpdate = payloadData;

      if (data.positionSide) {
        if (data.selectedSide === "left") {
          payload.positionLeft = data.positionSide;
          payload.positionRight = null;
        } else {
          payload.positionRight = data.positionSide;
          payload.positionLeft = null;
        }
      }

      interface Suggestion {
        displayText: string;
        message: string;
      }

      const filteredSuggestions = data.attentionGrabberSuggestions.filter(
        (suggestion): suggestion is Suggestion =>
          suggestion.displayText !== null && suggestion.message !== null,
      );

      payload.attentionGrabberSuggestions = filteredSuggestions;

      if (data.greetingMessage !== undefined) {
        if (data.greetingMessage === null) {
          payload.greetings = [];
        } else {
          payload.greetings = [
            { content: data.greetingMessage, pathRegex: null },
          ];
        }
      }

      if (data.deploymentHostname !== undefined) {
        if (data.deploymentHostname === null) {
          payload.deployments = [];
        } else {
          payload.deployments = [
            { hostname: data.deploymentHostname, pathRegex: null },
          ];
        }
      }

      type ImageFileIdKey =
        | "topBarImageFileId"
        | "botIconFileId"
        | "launcherIconFileId"
        | "launcherImageFileId";

      const imageFields: { key: FormImageKey; fileIdKey: ImageFileIdKey }[] = [
        { key: "topBarImage", fileIdKey: "topBarImageFileId" },
        { key: "botIcon", fileIdKey: "botIconFileId" },
        { key: "launcherIcon", fileIdKey: "launcherIconFileId" },
        { key: "launcherImage", fileIdKey: "launcherImageFileId" },
      ];

      for (const { key, fileIdKey } of imageFields) {
        const image = data[key];
        if (image) {
          const { id, presignedUrl } = await imageUploadMutation.mutateAsync({
            businessSlug,
            filename: image.name,
            contentType: image.type ? image.type : "application/octet-stream",
          });

          const response = await fetch(presignedUrl, {
            method: "PUT",
            body: image,
            headers: {
              "Content-Type": image.type,
              "x-ms-blob-type": "BlockBlob",
            },
          });

          if (!response.ok) {
            toast(`Update failed: ${key} upload error. Please try again.`);
            throw new Error(`Failed to upload ${key}`);
          }

          payload[fileIdKey] = id;
        } else if (image === null) {
          payload[fileIdKey] = null;
        }
      }

      updateMutation.mutate({ businessSlug, data: payload });
    },
  };
}
