export function MessageIcon() {
  return (
    <svg
      className="interface-icon o__inbox2 o__inbox2__chat-filled o__by-text"
      width="16"
      height="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H5l-2.293 2.293c-.63.63-1.707.184-1.707-.707V4Zm3 1.75A.75.75 0 0 1 4.75 5h6.5a.75.75 0 0 1 0 1.5h-6.5A.75.75 0 0 1 4 5.75Zm0 3.5a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 0 1.5h-3.5A.75.75 0 0 1 4 9.25Z"
      ></path>
    </svg>
  );
}
