import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/neto.webp";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">Neto Integration with Octocom</h2>
      <p className="text-justify text-gray-800">
        Elevate your eCommerce operations by integrating your Neto store with
        Octocom. This seamless integration allows you to automate and manage
        your Neto orders, customer interactions, and store analytics, providing
        a more efficient and streamlined shopping experience for your customers.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Effortless Integration:</span> Quickly
          and easily connect your Neto store to Octocom, enabling you to manage
          all aspects of your eCommerce business from a single, unified
          platform.
        </li>
        <li>
          <span className="font-medium">Automated Order Management:</span>{" "}
          Simplify your order processing by automating crucial tasks such as
          order confirmations, shipment tracking, and inventory updates. This
          automation reduces manual workload and improves the accuracy of your
          order fulfillment process.
        </li>
        <li>
          <span className="font-medium">Centralized Dashboard:</span> Oversee
          and manage all your Neto store activities from one intuitive
          dashboard. Track orders, customer inquiries, and store performance
          metrics without needing to switch between different systems.
        </li>
        <li>
          <span className="font-medium">Customer Support Integration:</span>{" "}
          Utilize Octocom&apos;s support tools to handle customer inquiries
          related to your Neto store. Respond to questions, resolve issues, and
          manage returns directly from the Octocom dashboard, ensuring a smooth
          and responsive customer service experience.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers you to manage your Neto store more
        effectively, offering both automation and direct management tools to
        enhance your eCommerce operations and boost customer satisfaction.
      </p>
    </div>
  );
}

export function NetoHqIntegrationDialog({ installed }: { installed: boolean }) {
  return (
    <>
      <IntegrationBlock
        title="Neto"
        shortDescription="Automate the customer support for your Neto store with Octocom."
        longDescription={<Description />}
        imageSrc={icon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
