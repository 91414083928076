import { formatDistanceToNow } from "date-fns";
import { env } from "@dashboard/env.ts";
import { useEffect, useState } from "react";
import { cn } from "@dashboard/utils/ui";
import { ChannelType } from "@db";
import { Route } from "@dashboard/routes/organization/$organizationSlug/_navbar/conversation/_conversationSidebar/_conversationList/$publicId";

interface RecentConversationLinkProps {
  publicId: string;
  isClosed: boolean;
  createdAt: Date;
  channel: ChannelType;
  orderId: string | null;
}

export function RecentConversationLink({
  publicId,
  isClosed,
  createdAt,
  channel,
  orderId,
}: RecentConversationLinkProps) {
  const { organizationSlug } = Route.useParams();

  const [, setTick] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((tick) => tick + 1);
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <a
      href={`${env.DASHBOARD_BASE_URL}/organization/${organizationSlug}/conversation/${publicId}`}
      target="_blank"
      rel="noreferrer"
    >
      <div className="hover:bg-offWhite flex flex-col items-start justify-between gap-1 rounded-xl px-3 py-2">
        <div className="flex w-full items-center justify-between gap-1">
          <span className="text-gray text-xs font-medium">
            Started {formatDistanceToNow(createdAt)} ago
          </span>
          <span
            className={cn(
              "ml-auto rounded-full px-3 py-0.5 text-[10px] font-semibold",
              isClosed
                ? "bg-gray-200 text-gray-800"
                : "bg-[#fff9cd] text-[#f9a600]",
            )}
          >
            {isClosed ? "Closed" : "Open"}
          </span>
        </div>
        <div className="flex w-full items-center justify-between gap-1">
          <span className="text-gray text-[12px]">Sent via {channel}</span>
          {orderId && (
            <span className="bg-blue-light text-blue ml-auto rounded-full px-3 py-0.5 text-[10px] font-semibold">
              #{orderId}
            </span>
          )}
        </div>
      </div>
    </a>
  );
}
