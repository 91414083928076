import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/Bitrix24.svg";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Bitrix24 Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Maximize your business efficiency by integrating Bitrix24 with Octocom.
        This powerful integration enables you to automate and manage your CRM,
        project management, and communication tasks within Bitrix24, providing a
        more cohesive and streamlined operational experience.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Smooth Integration:</span> Effortlessly
          connect your Bitrix24 account to Octocom, allowing you to manage all
          your business operations from a single, unified platform.
        </li>
        <li>
          <span className="font-medium">Automated Task Management:</span>{" "}
          Streamline your workflow by automating key tasks such as project
          updates, client communications, and CRM data synchronization. This
          automation reduces manual effort and enhances operational efficiency.
        </li>
        <li>
          <span className="font-medium">Unified Dashboard:</span> Monitor and
          manage all your Bitrix24 activities from one intuitive dashboard.
          Track projects, manage customer interactions, and oversee team
          performance without switching between multiple tools.
        </li>
        <li>
          <span className="font-medium">Integrated Communication Tools:</span>{" "}
          Leverage Octocom&apos;s communication features to manage team
          collaborations and customer interactions directly within Bitrix24.
          Respond to messages, share files, and coordinate tasks seamlessly,
          ensuring a cohesive workflow.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers you to manage your business more effectively,
        offering both automation and comprehensive management tools to enhance
        your operations and improve overall productivity.
      </p>
    </div>
  );
}

export function BitrixIntegrationDialog({ installed }: { installed: boolean }) {
  return (
    <>
      <IntegrationBlock
        title="Bitrix24"
        shortDescription="Automate the customer support for your Bitrix24 store with Octocom."
        longDescription={<Description />}
        imageSrc={icon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
