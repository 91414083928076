import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/braze.svg";

import { Separator } from "@dashboard/common/ui/separator";
import { BrazeSettingsForm } from "./BrazeSettingsForm";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">Braze Integration with Octocom</h2>
      <p className="text-justify text-gray-800">
        Simplify the management of your newsletter subscriptions by integrating
        Braze with Octocom. This integration allows for automatic updates to
        your Braze newsletter subscriptions whenever a customer makes a request,
        ensuring that your audience receives the communications they want, when
        they want them.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Automatic Subscription Updates:</span>{" "}
          Seamlessly update newsletter subscriptions in Braze based on customer
          requests through Octocom. This ensures that your customers&apos;
          preferences are always up-to-date without requiring manual
          intervention.
        </li>
        <li>
          <span className="font-medium">Efficient Customer Management:</span>{" "}
          Reduce the time and effort needed to manage subscription requests,
          allowing your team to focus on delivering high-quality content to your
          audience.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration streamlines the process of managing newsletter
        subscriptions, ensuring that your customers&apos; preferences are
        automatically reflected in your Braze account, leading to a more
        efficient and responsive communication strategy.
      </p>
    </div>
  );
}

export function BrazeIntegrationDialog({ installed }: { installed: boolean }) {
  return (
    <>
      <IntegrationBlock
        title="Braze"
        shortDescription="Automate your Braze newsletter subscriptions with Octocom."
        longDescription={<Description />}
        imageSrc={icon}
        installed={installed}
        settingsForm={<BrazeSettingsForm />}
      />
    </>
  );
}
