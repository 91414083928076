import { z } from "zod";

export const emailSettingsFormSchema = z.object({
  system: z.union([
    z.literal("smtp_imap"),
    z.literal("microsoft"),
    z.literal("gmail"),
  ]),
  backupHumanEmail: z.string().nullable(),
  footer: z.string().nullable(),
  maxCustomerEmailsPerThread: z.coerce
    .number({
      required_error: "Max Customer Emails Per Thread is required",
      invalid_type_error: "Max Customer Emails Per Thread is required",
    })
    .int({
      message: "Max Customer Emails Per Thread is required",
    })
    .refine((val) => val !== 0, {
      message: "Max Customer Emails Per Thread is required",
    }),
  respondToChainsInitiatedByCustomers: z.boolean(),
  enableOctocomBranding: z.boolean(),
});

export type EmailSettingsForm = z.infer<typeof emailSettingsFormSchema>;
