import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/instagram.svg";
import loginButton from "@dashboard/assets/facebook-black-btn.png";

import { Separator } from "@dashboard/common/ui/separator";
import { Route } from "..";
import { useRemoveIntegration } from "../-hooks/useRemoveIntegration";
import { installMetaIntegration } from "../-utils/installMetaIntegration";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Enhance Your eCommerce with Octocom&#39;s Instagram Integration
      </h2>
      <p className="text-justify text-gray-800">
        Boost your eCommerce capabilities by integrating Instagram with Octocom.
        This integration allows you to automate your Instagram interactions —
        such as comments and Direct Messages — as well as manage them directly
        from the Octocom platform, streamlining customer communication and
        support.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Effortless Instagram Integration:</span>{" "}
          Easily connect your Instagram account, enabling you to handle comments
          and Direct Messages seamlessly within Octocom.
        </li>
        <li>
          <span className="font-medium">Automated Customer Interactions:</span>{" "}
          Automate responses, manage customer inquiries, and send updates
          through Instagram Direct Messages, reducing manual work and improving
          response times.
        </li>
        <li>
          <span className="font-medium">Centralized Management:</span> Oversee
          all your Instagram interactions from a single, unified dashboard.
          Monitor comments, track conversations, and respond to customer
          inquiries without leaving Octocom.
        </li>
        <li>
          <span className="font-medium">Integrated Customer Support:</span>{" "}
          Utilize Octocom&#39;s robust customer support tools to manage
          Instagram comments and Direct Messages. Resolve issues promptly and
          ensure customer satisfaction directly from the Octocom platform.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers you to manage your Instagram interactions more
        effectively, improving your operations and enhancing customer
        satisfaction.
      </p>
    </div>
  );
}

export function InstagramIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  const { businessSlug } = Route.useParams();

  const removeIntegration = useRemoveIntegration();

  return (
    <IntegrationBlock
      title="Instagram"
      shortDescription="Automate your Instagram comments and messages with Octocom."
      longDescription={<Description />}
      imageSrc={icon}
      installed={installed}
      onRemove={() =>
        removeIntegration({
          integrationServiceName: "instagram",
        })
      }
      customButton={
        <img
          className="w-48 max-w-48 cursor-pointer"
          src={loginButton}
          onClick={() =>
            installMetaIntegration({ channel: "instagram", businessSlug })
          }
        />
      }
    />
  );
}
