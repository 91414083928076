import { toast } from "sonner";
import { Route } from "..";
import { useInvalidateConversationData } from "../../../-hooks/useInvalidateConversationData";

export function useCreateExternalThread() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug, publicId } = Route.useParams();

  const invalidate = useInvalidateConversationData({ publicId });

  const createMutation =
    trpc.dashboard.conversations.external.create.useMutation({
      onSuccess: async () => {
        await invalidate();
      },
      onError: (error) => {
        console.error("Error sending message", error);
        toast.error("Error creating external thread");
      },
    });

  return ({
    text,
    email,
    subject,
    fileIds,
  }: {
    text: string;
    email: string;
    subject: string;
    fileIds?: string[];
  }) =>
    createMutation.mutate({
      organizationSlug,
      publicId,
      text,
      email,
      subject,
      fileIds,
    });
}
