import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/kustomer.svg";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Kustomer Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Enhance your customer service operations by integrating Kustomer with
        Octocom. This seamless integration allows you to pass unhandled customer
        queries directly to the Kustomer help desk, ensuring that your support
        team can effectively manage and resolve issues across all communication
        channels.
      </p>
      <h3 className="text-xl font-semibold">Key Benefits:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Efficient Query Escalation:</span>{" "}
          Automatically route unhandled customer queries from Octocom to
          Kustomer, ensuring that every customer issue receives the attention it
          deserves.
        </li>
        <li>
          <span className="font-medium">
            Centralized Customer Interaction Management:
          </span>{" "}
          Manage all your customer interactions within Octocom, with the ability
          to escalate unresolved queries to Kustomer’s help desk for detailed
          follow-up.
        </li>
        <li>
          <span className="font-medium">
            Comprehensive Multi-Channel Support:
          </span>{" "}
          Utilize Kustomer’s robust platform to handle customer queries from
          multiple channels, ensuring quick and accurate responses that enhance
          customer satisfaction.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers your support team to deliver superior customer
        service by combining Octocom’s centralized management features with
        Kustomer’s multi-channel support tools, leading to faster issue
        resolution and improved customer experiences.
      </p>
    </div>
  );
}

export function KustomerIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        title="Kustomer"
        shortDescription="Pass unhandled customer support queries to Kustomer."
        longDescription={<Description />}
        imageSrc={icon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
