import { DialogHeader, DialogTitle } from "@dashboard/common/ui/dialog";
import { EditMacroForm } from "./EditMacroForm";
import { MacroAction } from "@be/modules/macros/macros.types";

interface EditMacroDialogContentProps {
  id: string;
  title: string;
  content: string | null;
  availableFor: string;
  actions: MacroAction[];
  setOpen: (opened: boolean) => void;
}

export function EditMacroDialogContent({
  id,
  title,
  content,
  availableFor,
  actions,
  setOpen,
}: EditMacroDialogContentProps) {
  return (
    <>
      <DialogHeader>
        <DialogTitle className="mx-auto">Edit Macro</DialogTitle>
      </DialogHeader>
      <EditMacroForm
        id={id}
        title={title}
        content={content}
        availableFor={availableFor}
        actions={actions}
        setOpen={setOpen}
      />
    </>
  );
}
