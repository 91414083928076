import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/")({
  validateSearch: z.object({
    code: z.string().optional(),
    state: z.string().optional(),
  }),
  beforeLoad: ({ search: { code, state } }) => {
    if (!code || !state) {
      throw redirect({ to: "/organization" });
    }
    throw redirect({
      to: `/organization`,
      search: { code, state },
    });
  },
});
