import { createFileRoute } from "@tanstack/react-router";
import { Table } from "@dashboard/common/Table/Table";
import { useArchiveUsers } from "./-hooks/useArchiveUsers";
import { AddUserDialogContent } from "./-components/AddUserDialogContent";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/team/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { organizationSlug },
  }) => {
    await trpcClientUtils.dashboard.users.getOrgUsers.ensureData({
      organizationSlug,
    });
  },
  component: TeamSettingsPage,
});

function TeamSettingsPage() {
  const { trpc } = Route.useRouteContext();

  const { organizationSlug } = Route.useParams();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const { archiveUsers } = useArchiveUsers();

  return (
    <div className="flex h-screen flex-col">
      <div className="flex h-screen flex-col">
        <Table
          title="Team Management"
          records={users}
          columns={[
            {
              header: "Full Name",
              accessor: "fullName",
            },
            {
              header: "Email",
              accessor: "email",
            },
          ]}
          addRecordDialogContent={() => <AddUserDialogContent />}
          deleteRecords={archiveUsers}
          recordName={{ singular: "Member", plural: "Members" }}
          noRecordsText="No members available"
        />
      </div>
    </div>
  );
}
