import { Routepath } from "@dashboard/App";
import { cn } from "@dashboard/utils/ui";
import { Link } from "@tanstack/react-router";

interface NestedRecord {
  [key: string]: string | null | undefined | NestedRecord;
}

interface SidebarButtonProps {
  text: string;
  to: Routepath;
  active?: boolean;
  count?: number;
  search?: NestedRecord;
  icon?: React.ElementType;
  className?: string;
}

export function SidebarButton({
  text,
  to,
  active,
  count,
  search,
  icon: Icon,
  className,
}: SidebarButtonProps) {
  return (
    <Link
      className={cn(
        "flex w-full items-center justify-between rounded-lg px-[12px] py-2 text-sm text-black hover:bg-white",
        active
          ? "bg-white font-semibold"
          : "bg-grey-lightest hover:!bg-gray-light",
        className,
      )}
      activeProps={{ className: "bg-white font-semibold" }}
      inactiveProps={{ className: "bg-grey-lightest hover:!bg-gray-light" }}
      to={to}
      search={search}
    >
      <div className="flex w-full items-center">
        {Icon && <Icon className="mr-2 h-4 w-4 text-black" />}
        {text}
      </div>
      {count !== undefined && (
        <span className="text-gray text-xs">{count}</span>
      )}
    </Link>
  );
}
