import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute } from "@tanstack/react-router";

import { Switch } from "@dashboard/common/ui/switch";
import { Button } from "@dashboard/common/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";

import {
  AccountSettingsForm,
  accountSettingsFormSchema,
} from "./-utils/accountSettingsFormSchema";

import { useSubmitForm } from "./-hooks/useSubmitForm";
import { useNotificationEffect } from "../../-hooks/useNotificationEffect";
import { useState } from "react";
import { Slider } from "@dashboard/common/ui/slider";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/account-settings/",
)({
  component: AccountSettingsPage,
});

function AccountSettingsPage() {
  const [submitCount, setSubmitCount] = useState(0);
  const { user } = Route.useRouteContext();

  const defaultValues: AccountSettingsForm = {
    ...user,
    notificationSoundLevel: [user.notificationSoundLevel],
  };

  const form = useForm<AccountSettingsForm>({
    resolver: zodResolver(accountSettingsFormSchema),
    defaultValues: defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm({ setSubmitCount });

  const enableNotifications = form.watch("enableNotifications");
  const enableNotificationSound = form.watch("enableNotificationSound");
  const notificationSoundLevel = form.watch("notificationSoundLevel");

  useNotificationEffect({
    settings: { enableNotifications, enableNotificationSound },
    submitCount,
  });

  async function playNotificationSound() {
    const audio = new Audio(`/notification-${notificationSoundLevel[0]}.wav`);
    await audio.play();
  }

  return (
    <div className="relative py-0">
      <Form {...form}>
        <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
          <div className="border-gray-light flex w-full flex-row border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">Your Account</h1>
            <Button type="submit" variant="publish" className="ml-auto">
              {form.formState.isSubmitting ? "Submitting..." : "Save"}
            </Button>
          </div>

          <div className="px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <FormField
                control={form.control}
                name="enableNotifications"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel className="w-48">Enable Notifications</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="enableNotificationSound"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel className="w-48">
                      Enable Notification Sound
                    </FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="notificationSoundLevel"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel className="w-48">
                      Notification Sound Level
                    </FormLabel>
                    <FormControl>
                      <Slider
                        className="w-40"
                        value={field.value}
                        onValueChange={field.onChange}
                        min={1}
                        max={5}
                        step={1}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                className="w-64"
                type="button"
                onClick={() => void playNotificationSound()}
              >
                Test Notification Sound
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
