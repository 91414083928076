import { IntegrationBlock } from "./IntegrationBlock";
import shopifyIcon from "@dashboard/assets/shopify.svg";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Shopify Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Elevate your eCommerce operations by integrating your Shopify store with
        Octocom. This powerful integration allows you to seamlessly automate and
        manage your Shopify orders, customer interactions, and store analytics,
        ensuring a more efficient and streamlined shopping experience for your
        customers.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Effortless Integration:</span> Connect
          your Shopify store to Octocom with ease, enabling you to manage all
          aspects of your eCommerce business directly from the Octocom platform.
        </li>
        <li>
          <span className="font-medium">Automated Order Management:</span>{" "}
          Streamline your order processing by automating key tasks such as order
          confirmations, shipment tracking, and inventory updates. This
          automation reduces manual workload and enhances the accuracy of your
          order fulfillment.
        </li>
        <li>
          <span className="font-medium">Centralized Dashboard:</span> Monitor
          and manage all your Shopify store activities from a single, intuitive
          dashboard. Track orders, customer inquiries, and store performance
          metrics without needing to switch between multiple platforms.
        </li>
        <li>
          <span className="font-medium">Customer Support Integration:</span>{" "}
          Leverage Octocom&apos;s support tools to handle customer inquiries
          related to your Shopify store. Respond to questions, resolve issues,
          and manage returns directly from the Octocom dashboard, ensuring a
          smooth and responsive customer service experience.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers you to manage your Shopify store more
        effectively, offering both automation and direct management tools to
        enhance your eCommerce operations and improve customer satisfaction.
      </p>
    </div>
  );
}

export function ShopifyIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        title="Shopify"
        shortDescription="Automate the customer support for your Shopify store with Octocom."
        longDescription={<Description />}
        imageSrc={shopifyIcon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
