import { ViewForm, ViewFormPayload } from "./viewFormSchema";

export function formatPayload(data: ViewForm) {
  const view: ViewFormPayload = {
    title: data.title,
    filters: {
      timeRange: undefined,
      businessId: undefined,
      assignee: undefined,
      language: undefined,
      channel: undefined,
      handedOff: undefined,
    },
  };

  for (const filter of data.filters) {
    switch (filter.key) {
      case "timeRange":
        view.filters.timeRange = filter.value as
          | "today"
          | "this-week"
          | "this-month";
        break;
      case "businessId":
        view.filters.businessId = filter.value;
        break;
      case "assignee":
        view.filters.assignee = filter.value;
        break;
      case "language":
        view.filters.language = filter.value;
        break;
      case "channel":
        view.filters.channel = filter.value as "email" | "web";
        break;
      case "handedOff":
        view.filters.handedOff = filter.value as "yes" | "no";
        break;
    }
  }

  return view;
}
