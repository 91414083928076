import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/solid";
import { LauncherProps } from "./Launcher";

export default function IconTextLauncher(props: LauncherProps) {
  return (
    <div className="mx-5 my-3 flex items-center justify-center sm:mx-4">
      {props.launcherIconSrc ? (
        <img
          style={{
            height: props.launcherIconSize,
            width: props.launcherIconSize,
          }}
          className="rounded"
          src={props.launcherIconSrc}
        />
      ) : (
        <ChatBubbleOvalLeftIcon
          style={{
            height: props.launcherIconSize,
            width: props.launcherIconSize,
            color: props.launcherIconColor,
          }}
        />
      )}
      <p
        className="ml-2 text-sm font-medium"
        style={{
          color: props.launcherTextColor,
        }}
      >
        {props.launcherText}
      </p>
    </div>
  );
}
