import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/knowledge/products/$productSlug",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug, productSlug },
  }) => {
    await trpcClientUtils.dashboard.products.get.ensureData({
      businessSlug,
      productSlug,
    });
  },
  component: ProductPage,
});

export function ProductPage() {
  const { businessSlug, productSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [product] = trpc.dashboard.products.get.useSuspenseQuery({
    businessSlug,
    productSlug,
  });

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div>
      <h3 className="text-xl font-bold">{product.contextual.name}</h3>
      <p>ID: {product.id}</p>
      <p>Slug: {product.slug}</p>
      <p>Price from: {product.contextual.minPrice}</p>
      <p>Price to: {product.contextual.maxPrice}</p>
      <p>Short description: {product.shortDescription}</p>
      <p>Long description: {product.longDescription}</p>

      <div className="mt-2">
        <h4 className="text-lg font-semibold">URL:</h4>
        {product.url.map((u) => (
          <p key={u.url}>
            {u.url} {u.language} {u.salesChannel}
          </p>
        ))}
      </div>

      <div className="mt-2">
        {product.variants.map((variant) => (
          <div key={variant.id}>
            <h4 className="text-lg font-semibold">Variant: {variant.title}</h4>
            <p>SKU: {variant.sku}</p>
            <p>Stock quantity: {variant.stockQuantity ?? "Unknown"}</p>
            <p>
              Can sell out of stock:{" "}
              {nullableBooleanToText(variant.canSellOutOfStock)}
            </p>
            <p>In stock: {nullableBooleanToText(variant.inStock)}</p>
            <p>
              Current price:{" "}
              {variant.contextual.pricing?.currentPrice ?? "Unknown"}
            </p>
            <p>
              Regular price:{" "}
              {variant.contextual.pricing?.regularPrice ?? "Unknown"}
            </p>
            <p>On sale: {variant.contextual.pricing?.onSale ? "Yes" : "No"}</p>
            <p>Description: {variant.longDescription}</p>
            <div className="mt-2">
              <h5 className="font-medium">Variant Metafields</h5>
              {variant.metafields.map((metafield) => (
                <p key={metafield.key}>
                  {metafield.key}: {metafield.value.slice(0, 100)}
                </p>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="mt-2">
        <h4 className="text-lg font-semibold">Collections</h4>
        {product.collections.map((collection) => (
          <p key={collection.slug}>{collection.slug}</p>
        ))}
      </div>

      <div className="mt-2">
        <h4 className="text-lg font-semibold">Metafields</h4>
        {product.metafields.map((metafield) => (
          <p key={metafield.key}>
            {metafield.key}: {metafield.value.slice(0, 100)}
          </p>
        ))}
      </div>

      <div className="mt-2">
        <h4 className="text-lg font-semibold">Images</h4>
        {product.images.map((image) => (
          <img
            key={image.url}
            src={image.url}
            alt={image.altText ?? "Unknown"}
            className="mt-2 w-96"
          />
        ))}
      </div>

      <div className="relative mt-2">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>
    </div>
  );
}

function nullableBooleanToText(value: boolean | null) {
  if (value === null) {
    return "Unknown";
  }

  return value ? "Yes" : "No";
}
