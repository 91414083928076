import Mustache from "mustache";
import { Macro, MacroAction } from "@be/modules/macros/macros.types";
import { MacroData } from "./macroTypes";

export function applyMacro({
  macro,
  macroData,
  setInput,
  setActions,
}: {
  macro: Macro;
  macroData?: MacroData;
  setInput: (input: string) => void;
  setActions: (actions: MacroAction[]) => void;
}) {
  const macroTemplate = macro.content;

  if (macroTemplate) {
    const content = macroData
      ? Mustache.render(macroTemplate, macroData)
      : macroTemplate;
    setInput(content);
  }

  setActions(macro.actions);
}
