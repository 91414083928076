import { IntegrationBlock } from "./IntegrationBlock";
import wixIcon from "@dashboard/assets/wix.svg";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Wix eCommerce Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Transform your Wix eCommerce store by integrating it with Octocom. This
        integration enables you to seamlessly manage and automate your online
        store&apos;s operations, from order processing to customer
        communications, ensuring a smooth and efficient shopping experience for
        your customers.
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Easy Integration:</span> Effortlessly
          connect your Wix eCommerce store with Octocom, allowing you to manage
          your store&apos;s operations directly from the Octocom platform.
        </li>
        <li>
          <span className="font-medium">Automated Order Processing:</span>{" "}
          Streamline your order management by automating tasks such as order
          confirmations, shipping notifications, and inventory updates. This
          automation reduces manual work and ensures that your customers receive
          timely updates on their purchases.
        </li>
        <li>
          <span className="font-medium">Centralized Store Management:</span>{" "}
          Oversee all aspects of your Wix store within a single, unified
          dashboard. Monitor orders, track customer inquiries, and analyze store
          performance without needing to navigate multiple platforms.
        </li>
        <li>
          <span className="font-medium">Customer Support Integration:</span>{" "}
          Utilize Octocom&apos;s customer support tools to manage inquiries and
          resolve issues related to your Wix store. Respond to customer
          questions directly from the Octocom dashboard, ensuring that your
          customers receive prompt and effective support.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers you to manage your Wix eCommerce store more
        effectively, providing you with automation and centralized management
        tools to enhance your store&apos;s operations and improve customer
        satisfaction.
      </p>
    </div>
  );
}

export function WixIntegrationDialog({ installed }: { installed: boolean }) {
  return (
    <>
      <IntegrationBlock
        title="Wix"
        shortDescription="Automate the customer support of your Wix store with Octocom."
        longDescription={<Description />}
        imageSrc={wixIcon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
