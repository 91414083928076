import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { SidebarButton } from "@dashboard/common/Sidebar/SidebarButton";
import { Route } from "../_conversationSidebar";

export function ViewAccordion() {
  const { trpc } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();
  const navigate = Route.useNavigate();

  const search = Route.useSearch();

  const [users] = trpc.dashboard.users.getOrgUsers.useSuspenseQuery({
    organizationSlug,
  });

  const [views] = trpc.dashboard.views.getAll.useSuspenseQuery({
    organizationSlug,
  });

  const filteredSearch: { filters?: { businessId?: string } } = {};

  if (search.filters?.businessId) {
    filteredSearch.filters = { businessId: search.filters.businessId };
  }

  return (
    <Accordion type="single" collapsible className="w-full">
      {users.length > 1 && (
        <AccordionItem value="item-1" className="border-b-0 !shadow-none">
          <AccordionTrigger className="hover:bg-gray-light rounded-lg px-[12px] py-2">
            <span className="text-sm font-semibold text-black">Teammates</span>
          </AccordionTrigger>
          <AccordionContent className="pb-0">
            {users.map((user, index) => (
              <SidebarButton
                key={index}
                text={user.fullName}
                active={search.view === `user-${user.id}`}
                to="/organization/$organizationSlug/conversation"
                search={{ ...filteredSearch, view: `user-${user.id}` }}
              />
            ))}
          </AccordionContent>
        </AccordionItem>
      )}
      <AccordionItem value="item-2" className="border-b-0 !shadow-none">
        <AccordionTrigger
          className="hover:bg-gray-light rounded-lg px-[12px] py-2"
          extraActionTooltip="Create a new view"
          extraAction={() => {
            void navigate({
              from: "/organization/$organizationSlug/conversation",
              to: "/organization/$organizationSlug/conversation/views",
            });
          }}
        >
          <span className="text-sm font-semibold text-black">Views</span>
        </AccordionTrigger>
        <AccordionContent className="pb-0">
          {views.length === 0 && (
            <span className="text-gray px-[12px]">No views</span>
          )}
          {views.map((view, index) => (
            <SidebarButton
              key={index}
              text={view.title}
              to="/organization/$organizationSlug/conversation"
              active={search.view === view.id}
              search={{ ...filteredSearch, view: view.id }}
            />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
