import { Route } from "..";
import { toast } from "sonner";
import { ArticleForm } from "../-utils/articleForm";

export const useCreateArticle = ({
  setOpen,
}: {
  setOpen: (value: boolean) => void;
}) => {
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();
  const { businessSlug } = Route.useParams();

  const creationMutation = trpc.dashboard.articles.create.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.articles.getAll.invalidate({ businessSlug });

      setOpen(false);
      toast.success("Article created");
    },
    onError: () => {
      toast.error("Failed to create article");
    },
  });

  return {
    onSubmit: ({ title, content }: ArticleForm) => {
      creationMutation.mutate({ businessSlug, title, content });
    },
  };
};
