import { TimeRangeFilter } from "./TimeRangeFilter";
import { BusinessFilter } from "./BusinessFilter";
import { AssigneeFilter } from "./AssigneeFilter";
import { LanguageFilter } from "./LanguageFilter";
import { ChannelFilter } from "./ChannelFilter";
import { HandedOffFilter } from "./HandedOffFilter";
import { ClearFilters } from "./ClearFilters";

export function SearchFilters() {
  return (
    <div className="flex w-full flex-col items-start justify-start gap-2 px-2 py-4">
      <TimeRangeFilter />
      <BusinessFilter />
      <AssigneeFilter />
      <LanguageFilter />
      <ChannelFilter />
      <HandedOffFilter />

      <ClearFilters />
    </div>
  );
}
