import { authenticate } from "@dashboard/utils/auth";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute("/organization")({
  validateSearch: z.object({
    code: z.string().optional(),
    state: z.string().optional(),
  }),
  beforeLoad: async ({
    context: { trpcClientUtils },
    search: { code: auth0CallbackCode },
  }) => {
    const { logout } = await authenticate({ auth0CallbackCode });

    const user = await trpcClientUtils.dashboard.users.getCurrent.ensureData();

    return {
      user,
      logout,
    };
  },
});
