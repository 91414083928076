import { toast } from "sonner";
import { CancelationData } from "@be/modules/orders/orders.types";
import { Route } from "../..";

export function useOrderCancel() {
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const cancelMutation = trpc.dashboard.conversations.orders.cancel.useMutation(
    {
      onError: (error) => {
        toast.error("Failed to cancel order.");
        console.error(error);
      },
    },
  );

  return {
    cancelOrder: ({
      externalOrderId,
      cancelationData,
      onSuccess,
    }: {
      externalOrderId: string;
      cancelationData: CancelationData;
      onSuccess: () => void;
    }) => {
      cancelMutation.mutate(
        {
          organizationSlug,
          publicId,
          externalOrderId,
          cancelationData,
        },
        {
          onSuccess: () => {
            onSuccess();
            toast.success("Order canceled successfully.");
          },
        },
      );
    },
  };
}
