import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogCancel,
  AlertDialogPortal,
  AlertDialogHeader,
} from "@dashboard/common/ui/alert-dialog";
import { Button } from "@dashboard/common/ui/button";
import { AlertDialogAction } from "@radix-ui/react-alert-dialog";
import { ShopifySubscription } from "../-hooks/useSubscribe";

interface ShopifySubscriptionDialogProps {
  onConfirm: ({
    subscriptionType,
  }: {
    subscriptionType: ShopifySubscription;
  }) => void;
  currentSub?: string;
}

export function ShopifySubscriptionDialog({
  onConfirm,
  currentSub,
}: ShopifySubscriptionDialogProps) {
  const subscriptionTypes = [
    {
      name: "Basic",
      price: "$100",
      maxConvos: 350,
      overageFee: "$40",
    },
    {
      name: "Advanced",
      price: "$300",
      maxConvos: 1200,
      overageFee: "$36",
    },
    {
      name: "Enterprise",
      price: "$800",
      maxConvos: 3200,
      overageFee: "$30",
    },
  ];

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button>
          {currentSub ? "See possible upgrades" : "See our options"}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogContent className="max-w-fit">
          <AlertDialogHeader>
            <AlertDialogTitle>Subscribe</AlertDialogTitle>
          </AlertDialogHeader>

          <div className="flex">
            {subscriptionTypes.map((s) => (
              <div key={s.name}>
                <AlertDialogAction
                  key={s.name}
                  asChild
                  onClick={() =>
                    onConfirm({
                      subscriptionType:
                        s.name as unknown as ShopifySubscription,
                    })
                  }
                >
                  <div className="m-1 border border-black p-2">
                    <div className="mt-1 flex justify-center border-b border-black font-bold">
                      <h1>{s.name}</h1>
                    </div>
                    <div className="m-5">
                      <p>Price: {s.price} / month</p>
                      <p>Maximum conversations: {s.maxConvos}</p>
                      <p>
                        Overage fee: {s.overageFee} per each additional 100
                        conversations after {s.maxConvos} conversations{" "}
                      </p>
                    </div>
                    <div className="flex justify-center">
                      <Button disabled={s.name === currentSub}>
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </AlertDialogAction>
              </div>
            ))}
          </div>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}
