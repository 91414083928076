import { Dialog, DialogContent } from "@dashboard/common/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@dashboard/common/ui/dropdown-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";

import { DeleteRecordDialogContent } from "./DeleteRecordDialogContent";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { RecordWithId } from "../Table";

interface RecordActionsProps<Record extends RecordWithId> {
  id: string;
  record: Record;
  recordName: { singular: string; plural: string };
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  deleteRecords?: (ids: string[]) => void;
  editRecordDialogContent?: (
    record: Record,
    setOpen: Dispatch<SetStateAction<boolean>>,
  ) => ReactNode;
  contextActions?: { label: string; action: (ids: string[]) => void }[];
}

export function RecordActions<Record extends RecordWithId>({
  id,
  record,
  recordName,
  setSelectedIds,
  deleteRecords,
  editRecordDialogContent,
  contextActions,
}: RecordActionsProps<Record>) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <DotsVerticalIcon className="h-4 w-4" />
        </DropdownMenuTrigger>
        <DropdownMenuContent collisionPadding={10}>
          {editRecordDialogContent && (
            <DropdownMenuItem onSelect={() => setIsEditDialogOpen(true)}>
              Edit
            </DropdownMenuItem>
          )}
          {deleteRecords && (
            <DropdownMenuItem
              className="text-red-500 hover:!text-red-500"
              onSelect={() => setIsDeleteDialogOpen(true)}
            >
              Delete
            </DropdownMenuItem>
          )}
          {contextActions?.map(({ label, action }) => (
            <DropdownMenuItem key={label} onSelect={() => action([id])}>
              {label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      {deleteRecords && (
        <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
          <DeleteRecordDialogContent
            ids={[id]}
            recordName={recordName}
            setSelectedIds={setSelectedIds}
            deleteRecords={() => {
              deleteRecords([id]);
            }}
          />
        </Dialog>
      )}

      {editRecordDialogContent && (
        <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
          <DialogContent className="max-h-[80vh] overflow-scroll sm:max-w-md">
            {editRecordDialogContent(record, setIsEditDialogOpen)}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
