import { useConversation } from "../-hooks/useConversation.hook";
import { Route } from "..";
import { toast } from "sonner";
import { useEffect, useState } from "react";

export function useTranslation() {
  const conversation = useConversation();
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const search = Route.useSearch();
  const navigate = Route.useNavigate();

  const translationMutation =
    trpc.dashboard.conversations.translateMessage.useMutation({
      onSuccess: () => {
        toast.success("Conversation translated successfully");
        void trpcUtils.dashboard.conversations.get
          .invalidate({
            organizationSlug,
            publicId,
          })
          .then(() =>
            navigate({ search: { ...search, useTranslation: true } }),
          );
      },
      onError: (error) => {
        console.error("Error translating message", error);
        toast.error("Something went wrong while translating the conversation");
      },
    });

  const [translationIsEnabled, setIsTranslationEnabled] = useState(
    search.useTranslation ?? conversation.hasTranslation,
  );

  useEffect(() => {
    setIsTranslationEnabled(
      search.useTranslation ?? conversation.hasTranslation,
    );
  }, [search.useTranslation, conversation.hasTranslation]);

  const translate = (translate: boolean) => {
    if (translate) {
      if (conversation.hasTranslation) {
        void navigate({ search: { ...search, useTranslation: true } });
        return;
      }
      translationMutation.mutate({ organizationSlug, publicId });
    } else {
      void navigate({ search: { ...search, useTranslation: false } });
    }
  };

  return {
    translationIsEnabled,
    translate,
  };
}
