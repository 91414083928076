import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogPortal,
  AlertDialogHeader,
} from "@dashboard/common/ui/alert-dialog";
import { Button } from "@dashboard/common/ui/button";
import { AlertDialogAction } from "@radix-ui/react-alert-dialog";
import loginButton from "@dashboard/assets/facebook-black-btn.png";
import { MetaConnection } from "@be/modules/integrations/thirdParty/meta/meta.types";

interface MetaIntegrationDialogProps {
  channel: MetaConnection;
  description: string;
  onConfirm: () => void;
}

export function MetaIntegrationDialog({
  channel,
  description,
  onConfirm,
}: MetaIntegrationDialogProps) {
  let connected: boolean = "ids" in channel && !!channel.ids;
  if (!connected && "instaInfo" in channel) {
    if (channel.instaInfo?.find((id) => id)) {
      connected = true;
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button className="mt-auto">
          {connected ? "Disconnect" : "Connect"}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {connected ? "Disconnect " : "Connect "}
              {channel.type}
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogDescription>{description}</AlertDialogDescription>
          <AlertDialogAction onClick={onConfirm}>
            {connected ? (
              <Button className="w-full">Disconnect</Button>
            ) : (
              <img className="max-h-40" src={loginButton}></img>
            )}
          </AlertDialogAction>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}
