import { createFileRoute } from "@tanstack/react-router";
import { ShopifySubscriptionDialog } from "./-components/ShopifySubscriptionDialog";
import { useSubscribe } from "./-hooks/useSubscribe";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/shopify-subscription/",
)({
  component: ShopifySubscriptionManagement,
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.shopify.getSubscription.ensureData({
      businessSlug,
    });
    await trpcClientUtils.dashboard.shopify.getThemes.ensureData({
      businessSlug,
    });
  },
});

function ShopifySubscriptionManagement() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const subscribe = useSubscribe();

  const [subscription] =
    trpc.dashboard.shopify.getSubscription.useSuspenseQuery({ businessSlug });

  if (!subscription) {
    return (
      <div className="mt-9 flex items-center justify-center">
        <div className="block text-center">
          <h1 className="mb-4">You do not have an active subscription</h1>
          <ShopifySubscriptionDialog onConfirm={subscribe} />
        </div>
      </div>
    );
  }

  const subscriptionOverage =
    subscription.subscriptionType === "Basic"
      ? 40
      : subscription.subscriptionType === "Advanced"
      ? 36
      : subscription.subscriptionType === "Enterprise"
      ? 30
      : undefined;

  const maxConvos =
    subscription.subscriptionType === "Basic"
      ? 350
      : subscription.subscriptionType === "Advanced"
      ? 1200
      : subscription.subscriptionType === "Enterprise"
      ? 3200
      : undefined;

  if (subscriptionOverage === undefined || maxConvos === undefined) {
    throw new Error("Invalid subscription type");
  }

  return (
    <>
      <div className="flex items-center justify-start">
        <div className="block">
          <h1>Shopify Subscription Management</h1>
          <h2>Subscription Info</h2>
          <p>Plan: {subscription.subscriptionType}</p>
          <p className="mb-4">
            Overage fee for exceeding conversation plan limit:
            <br />${subscriptionOverage} overage fee per each additional 100
            conversations after ${maxConvos} conversations
          </p>
          <ShopifySubscriptionDialog
            currentSub={subscription.subscriptionType}
            onConfirm={subscribe}
          />
        </div>
      </div>
    </>
  );
}
