import { Button } from "@dashboard/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@dashboard/common/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@dashboard/common/ui/tabs";
import { Separator } from "@dashboard/common/ui/separator";
import { ReactNode, useEffect, useState } from "react";

interface IntegrationBlockProps {
  title: string;
  shortDescription: string;
  longDescription: ReactNode;
  imageSrc: string;
  onRemove?: () => void;
  manualInstallation?: boolean;
  installed: boolean;
  settingsForm?: ReactNode;
  customButton?: ReactNode;
}

export function IntegrationBlock({
  title,
  shortDescription,
  longDescription,
  imageSrc,
  onRemove,
  manualInstallation = false,
  installed,
  settingsForm,
  customButton,
}: IntegrationBlockProps) {
  const [showTabs, setShowTabs] = useState(installed);
  const [tab, setTab] = useState<"description" | "settings">("description");

  function deleteIntegration() {
    if (onRemove) {
      onRemove();
    }

    setShowTabs(false);
  }

  function startInstallation() {
    setShowTabs(true);
    setTab("settings");
  }

  useEffect(() => {
    if (!installed && tab === "description") {
      setShowTabs(false);
    }
  }, [tab, installed]);

  function Content() {
    if (!settingsForm) {
      return (
        <div className="h-full overflow-y-scroll p-6">{longDescription}</div>
      );
    }

    if (showTabs) {
      return (
        <Tabs
          defaultValue="description"
          className="h-full w-full overflow-y-scroll p-6"
          value={tab}
          onValueChange={(value) => setTab(value as "description" | "settings")}
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="description">Description</TabsTrigger>
            <TabsTrigger value="settings">Settings</TabsTrigger>
          </TabsList>
          <TabsContent value="description">{longDescription}</TabsContent>
          <TabsContent value="settings">{settingsForm}</TabsContent>
        </Tabs>
      );
    } else {
      return (
        <div className="h-full overflow-y-scroll p-6">{longDescription}</div>
      );
    }
  }

  return (
    <Dialog>
      <DialogTrigger>
        <div className="border-blue-light hover:bg-blue-light hover:border-blue flex h-full cursor-pointer flex-col items-start justify-start gap-2 rounded-lg border bg-white p-4 transition-all duration-200 ease-in-out hover:shadow-lg">
          <div className="flex w-full items-start justify-between">
            <img src={imageSrc} alt={title} className="h-12" />
            {installed && (
              <div className="rounded-lg bg-green-600 px-2 py-1 text-white shadow">
                Installed
              </div>
            )}
          </div>
          <h3 className="text-lg font-medium text-gray-800">{title}</h3>
          <p className="text-left text-gray-600">{shortDescription}</p>
        </div>
      </DialogTrigger>
      <DialogContent className="flex h-[70vh] w-[60%] max-w-[60%] flex-col gap-0 overflow-hidden p-0">
        <DialogHeader className="border-blue-light bg-accent flex h-32 flex-row gap-4 border-b p-10">
          <img src={imageSrc} alt={title} className="h-12" />
          <div className="flex flex-col gap-1">
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>{shortDescription}</DialogDescription>
          </div>
        </DialogHeader>
        <div className="flex h-full items-start justify-start overflow-hidden">
          <Content />
          <Separator orientation="vertical" />
          <div className="p-6">
            {installed ? (
              onRemove ? (
                <Button
                  type="button"
                  variant="destructive"
                  className="w-40"
                  onClick={deleteIntegration}
                >
                  Remove
                </Button>
              ) : (
                <Button type="button" variant="publish" className="w-40">
                  Installed
                </Button>
              )
            ) : customButton ? (
              customButton
            ) : manualInstallation ? (
              <Button type="button" variant="publish" className="w-40">
                <a
                  href="mailto:info@octocom.ai"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact us to install
                </a>
              </Button>
            ) : (
              <Button
                type="button"
                variant="publish"
                className="w-40"
                onClick={startInstallation}
              >
                Install
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
