import { useFormContext } from "react-hook-form";
import ImageLauncher from "./ImageLauncher";
import IconTextLauncher from "./IconTextLauncher";
import IconLauncher from "./IconLauncher";
import { formDefaults } from "../../-utils/formDefaults";
import { WebChatConfigForm } from "../../-utils/formSchema";

export interface LauncherProps {
  positionSide: string;
  selectedSide: string;
  launcherText?: string;
  launcherBackgroundColor?: string;
  launcherTextColor?: string;
  launcherIconColor?: string;
  launcherSize?: string;
  launcherIconSize?: string;
  launcherIconSrc?: string;
  launcherImageSrc?: string;
  isChatWindowOpen: boolean;
}

function Launcher({
  isChatWindowOpen,
  onClick,
}: {
  isChatWindowOpen: boolean;
  onClick: () => void;
}) {
  const { watch } = useFormContext<WebChatConfigForm>();

  const props = {
    selectedSide: watch("selectedSide"),

    positionSide: watch("positionSide") ?? formDefaults.positionSide,
    launcherType: watch("launcherType"),
    launcherText: watch("launcherText") ?? formDefaults.launcherText,
    launcherBackgroundColor:
      watch("launcherBackgroundColor") ?? formDefaults.launcherBackgroundColor,
    launcherTextColor:
      watch("launcherTextColor") ?? formDefaults.launcherTextColor,
    launcherIconColor:
      watch("launcherIconColor") ?? formDefaults.launcherIconColor,
    launcherSize: watch("launcherSize") ?? formDefaults.launcherSize,
    launcherIconSize:
      watch("launcherIconSize") ?? formDefaults.launcherIconSize,
    launcherIconSrc: watch("launcherIconSrc") ?? formDefaults.launcherIconSrc,
    launcherImageSrc:
      watch("launcherImageSrc") ?? formDefaults.launcherImageSrc,
    isChatWindowOpen,
  };

  function renderLauncher() {
    if (props.launcherType === "image" && props.launcherImageSrc) {
      return <ImageLauncher {...props} />;
    } else if (props.launcherType === "icon_text") {
      return <IconTextLauncher {...props} />;
    } else {
      return <IconLauncher {...props} />;
    }
  }

  return (
    <div
      onClick={onClick}
      style={{
        [props.selectedSide]: props.positionSide,
        height: props.launcherSize,
        minWidth: props.launcherSize,
        backgroundColor:
          props.launcherType === "image" && props.launcherImageSrc
            ? "transparent"
            : props.launcherBackgroundColor,
      }}
      className="absolute bottom-[10px] z-[2147483648] flex cursor-pointer items-center justify-center rounded-full hover:scale-105"
    >
      {renderLauncher()}
    </div>
  );
}

export default Launcher;
