import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/dixa.svg";

import { Separator } from "@dashboard/common/ui/separator";
import { DixaSettingsForm } from "./DixaSettingsForm";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">Dixa Integration with Octocom</h2>
      <p className="text-justify text-gray-800">
        Enhance your customer support workflow by integrating Dixa with Octocom.
        This seamless integration allows you to pass unhandled customer queries
        directly to the Dixa help desk, ensuring that your support team can
        address customer issues promptly and effectively.
      </p>
      <h3 className="text-xl font-semibold">Key Benefits:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Seamless Query Transfer:</span>{" "}
          Effortlessly route unhandled customer queries from Octocom to Dixa,
          ensuring that no customer issue goes unresolved.
        </li>
        <li>
          <span className="font-medium">Centralized Support Management:</span>{" "}
          Manage all your customer interactions through Octocom, with the
          ability to escalate unresolved queries to the Dixa help desk for
          specialized attention.
        </li>
        <li>
          <span className="font-medium">Improved Response Times:</span> Ensure
          that customer issues are addressed swiftly by automatically passing
          unhandled queries to Dixa, enabling your team to deliver timely
          support and improve customer satisfaction.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers your support team to efficiently manage and
        resolve customer queries by combining the centralized management
        capabilities of Octocom with the robust help desk tools of Dixa, leading
        to better customer service outcomes.
      </p>
    </div>
  );
}

export function DixaIntegrationDialog({ installed }: { installed: boolean }) {
  return (
    <>
      <IntegrationBlock
        title="Dixa"
        shortDescription="Pass unhandled customer support queries to Dixa."
        longDescription={<Description />}
        imageSrc={icon}
        installed={installed}
        settingsForm={<DixaSettingsForm />}
      />
    </>
  );
}
