import { marked } from "marked";
import DOMPurify from "dompurify";
import { useMemo } from "react";
import "@dashboard/styles/markdownContent.css";
import { cn } from "@dashboard/utils/ui";

export function MarkdownContent({
  content,
  className,
}: {
  content: string;
  className?: string;
}) {
  const htmlContent = useMemo(
    () => DOMPurify.sanitize(marked(content)),
    [content],
  );

  return (
    <div
      className={cn("markdown-content", className)}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
}
