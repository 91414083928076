import {} from "@radix-ui/react-alert-dialog";
import { createFileRoute } from "@tanstack/react-router";
import { MetaIntegrationDialog } from "./-components/MetaIntegrationDialog";
import { env } from "@dashboard/env";
import { toast } from "sonner";
import { MetaConnection } from "@be/modules/integrations/thirdParty/meta/meta.types";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/meta-integration/",
)({
  component: MetaIntegrationsPage,
  beforeLoad: async ({
    params: { businessSlug },
    context: { trpcClientUtils },
  }) => {
    await trpcClientUtils.dashboard.meta.getConnected.invalidate({
      businessSlug,
    });

    await trpcClientUtils.dashboard.meta.getConnected.ensureData({
      businessSlug,
    });
  },
});

function MetaIntegrationsPage() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();
  const trpcUtils = trpc.useUtils();

  const [connectedChannels] = trpc.dashboard.meta.getConnected.useSuspenseQuery(
    {
      businessSlug,
    },
  );

  const disconnect = trpc.dashboard.meta.disconnect.useMutation({
    onSuccess: async () =>
      await trpcUtils.dashboard.meta.getConnected.invalidate(),
    onError: () => {
      toast.error("Failed to disconnect");
    },
  });

  const login = ({ channel }: { channel: MetaConnection }) => {
    if (channel.type === "Instagram" && channel.instaInfo) {
      disconnect.mutate({ businessSlug, waba: false });
      return;
    }

    if (channel.type !== "Instagram" && channel.ids) {
      disconnect.mutate({ businessSlug, waba: channel.type === "Whatsapp" });
      return;
    }

    let configId;

    switch (channel.type) {
      case "Messenger":
        configId = env.DASHBOARD_MESSENGER_CONFIGURATION_ID;
        break;
      case "Whatsapp":
        configId = env.DASHBOARD_WHATSAPP_CONFIGURATION_ID;
        break;
      case "Instagram":
        configId = env.DASHBOARD_INSTAGRAM_CONFIGURATION_ID;
        break;
    }

    const url = "https://www.facebook.com/v18.0/dialog/oauth?";

    const params = new URLSearchParams({
      client_id: env.DASHBOARD_META_APP_ID,
      redirect_uri: env.DASHBOARD_META_REDIRECT_URI,
      response_type: "code",
      state: JSON.stringify({ configId, businessSlug }),
      config_id: configId,
    });

    localStorage.setItem(
      "authState",
      JSON.stringify({ configId, businessSlug }),
    );

    const fullUrl = `${url}${params.toString()}`;

    window.location.href = fullUrl;
  };

  return (
    <div className="flex h-screen flex-col">
      <div className="flex h-screen flex-col ">
        <div className="grid grid-cols-3 px-10 py-10">
          {connectedChannels.map((channel: MetaConnection) => (
            <div
              key={channel.type}
              className="flex max-w-[350px] flex-col rounded-xl border px-5 py-3"
            >
              <h2>{channel.type}</h2>
              <span className="my-1">You are currently:</span>
              <span>
                {channel.type !== "Instagram" ? (
                  channel.ids ? (
                    <>
                      <span className="text-green-500"> Connected</span>
                      <div>Connected IDs:</div>
                      {channel.ids.map((id) => (
                        <div key={id}>{id}</div>
                      ))}
                    </>
                  ) : (
                    "Not connected"
                  )
                ) : channel.instaInfo ? (
                  <>
                    <span className="text-green-500"> Connected</span>
                    {channel.instaInfo.map((info) => (
                      <div key={info.id}>
                        <div>Instagram account ID: {info.id}</div>
                        <div>Instagram account name: {info.name}</div>
                        <div>Total media likes: {info.totalMediaLikes}</div>
                      </div>
                    ))}
                  </>
                ) : (
                  "Not connected"
                )}
              </span>
              <MetaIntegrationDialog
                channel={channel}
                description={`Communication with Octocom bots via ${channel.type}`}
                onConfirm={() => login({ channel })}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
