import { Tooltip } from "@dashboard/common/ui/tooltip";
import { X } from "lucide-react";
import { TimeRange } from "./FilterComponents/TimeRange";
import { HandedOff } from "./FilterComponents/HandedOff";
import { Channel } from "./FilterComponents/Channel";
import { Language } from "./FilterComponents/Language";
import { Assignee } from "./FilterComponents/Assignee";
import { Business } from "./FilterComponents/Business";
import { FilterKeys } from "@be/modules/dashboard/views/views.types";
import { cn } from "@dashboard/utils/ui";

function FilterComponent({
  filterKey,
  setFormValue,
  data,
  error,
  clearErrors,
}: {
  filterKey: FilterKeys;
  setFormValue: (value: string) => void;
  data: string | null;
  error: boolean;
  clearErrors: () => void;
}) {
  switch (filterKey) {
    case "timeRange":
      return (
        <TimeRange
          setFormValue={setFormValue}
          data={data}
          error={error}
          clearErrors={clearErrors}
        />
      );
    case "handedOff":
      return (
        <HandedOff
          setFormValue={setFormValue}
          data={data}
          error={error}
          clearErrors={clearErrors}
        />
      );
    case "channel":
      return (
        <Channel
          setFormValue={setFormValue}
          data={data}
          error={error}
          clearErrors={clearErrors}
        />
      );
    case "language":
      return (
        <Language
          setFormValue={setFormValue}
          data={data}
          error={error}
          clearErrors={clearErrors}
        />
      );
    case "assignee":
      return (
        <Assignee
          setFormValue={setFormValue}
          data={data}
          error={error}
          clearErrors={clearErrors}
        />
      );
    case "businessId":
      return (
        <Business
          setFormValue={setFormValue}
          data={data}
          error={error}
          clearErrors={clearErrors}
        />
      );
  }
}

export function Filter({
  filterKey,
  data,
  remove,
  setFormValue,
  error,
  clearErrors,
}: {
  filterKey: FilterKeys;
  data: string | null;
  remove: () => void;
  setFormValue: (value: string) => void;
  error: boolean;
  clearErrors: () => void;
}) {
  const handleRemove = () => {
    remove();
  };

  const errorClass = error
    ? "group-hover:bg-red-200 group-hover:text-red-700"
    : "";

  return (
    <>
      <div className="group relative flex items-center justify-between gap-2">
        <div
          className={cn(
            "group-hover:bg-accent absolute right-2 z-10 h-7 w-7 rounded bg-transparent blur-sm",
            errorClass,
          )}
        />
        <Tooltip
          triggerAsChild
          content="Remove filter"
          side="top"
          trigger={
            <X
              className={cn(
                "group-hover:bg-accent group-hover:text-blue absolute right-2 z-20 h-4 w-4 cursor-pointer rounded-full bg-transparent text-transparent transition-colors",
                errorClass,
              )}
              onClick={handleRemove}
            />
          }
        />
        <FilterComponent
          filterKey={filterKey}
          setFormValue={setFormValue}
          data={data}
          error={error}
          clearErrors={clearErrors}
        />
      </div>
    </>
  );
}
