import { z } from "zod";

export const webChatConfigFormSchema = z.object({
  id: z.string().uuid(),

  // Top bar settings
  topBarTitle: z.string().nullish(),
  topBarImage: z
    .any()
    .refine((value) => value instanceof File, {
      message: "Please upload an image",
    })
    .transform((value) => value as File)
    .nullish(),
  topBarImageSrc: z.string().optional(),
  topBarBackgroundColor: z.string().nullish(),
  topBarTextColor: z.string().nullish(),
  topBarIconColor: z.string().nullish(),
  enableTopBarBorder: z.boolean(),

  // Bot settings
  botIcon: z
    .any()
    .refine((value) => value instanceof File, {
      message: "Please upload an image",
    })
    .transform((value) => value as File)
    .nullish(),
  botIconSrc: z.string().optional(),
  botMessageBackgroundColor: z.string().nullish(),
  botMessageTextColor: z.string().nullish(),
  greetingMessage: z.string().nullish(),
  requestEmailBeforeChat: z.boolean(),

  // Customer settings
  customerMessageBackgroundColor: z.string().nullish(),
  customerMessageTextColor: z.string().nullish(),

  // Disclaimer Settings
  requireTermsAndConditionsAgreement: z.boolean(),
  disclaimerText: z.string().nullish(),
  disclaimerButtonBackgroundColor: z.string().nullish(),
  disclaimerButtonTextColor: z.string().nullish(),
  enableDisclaimer: z.boolean(),

  // Launcher settings
  launcherType: z.union([
    z.literal("icon"),
    z.literal("icon_text"),
    z.literal("image"),
  ]),
  launcherText: z.string().nullish(),
  launcherBackgroundColor: z.string().nullish(),
  launcherTextColor: z.string().nullish(),
  launcherIconColor: z.string().nullish(),
  launcherSize: z.string().nullish(),
  launcherIconSize: z.string().nullish(),
  launcherIcon: z
    .any()
    .refine((value) => value instanceof File, {
      message: "Please upload an image",
    })
    .transform((value) => value as File)
    .nullish(),
  launcherIconSrc: z.string().nullish(),
  launcherImage: z
    .any()
    .refine((value) => value instanceof File, {
      message: "Please upload an image",
    })
    .transform((value) => value as File)
    .nullish(),
  launcherImageSrc: z.string().nullish(),

  // Position settings
  positionBottom: z.string().nullish(),
  positionSide: z.string().nullish(),
  selectedSide: z.enum(["left", "right"]),

  // Attention grabber settings
  enableAttentionGrabbers: z.boolean(),
  attentionGrabberMessage: z.string().nullish(),
  attentionGrabberSuggestionBubbleBackgroundColor: z.string().nullish(),
  attentionGrabberSuggestionBubbleTextColor: z.string().nullish(),
  attentionGrabberSuggestionBubbleHoverBackgroundColor: z.string().nullish(),
  attentionGrabberSuggestionBubbleHoverTextColor: z.string().nullish(),
  attentionGrabberDelay: z.number().nullish(),
  attentionGrabberSuggestions: z.array(
    z.object({
      displayText: z.string().nullable(),
      message: z.string().nullable(),
    }),
  ),

  // Deployment
  deploymentHostname: z.string().nullish(),

  // Live Chat
  liveChatResponseTime: z.number().nonnegative().nullish(),
  liveChatTimeout: z.number().nonnegative().nullish(),

  // Admin
  enableOctocomBranding: z.boolean(),
  enableLiveChat: z.boolean(),
});

export type WebChatConfigForm = z.infer<typeof webChatConfigFormSchema>;

export type FormImageKey =
  | "topBarImage"
  | "botIcon"
  | "launcherIcon"
  | "launcherImage";

export type FormColorKey =
  | "topBarBackgroundColor"
  | "topBarTextColor"
  | "topBarIconColor"
  | "botMessageBackgroundColor"
  | "botMessageTextColor"
  | "customerMessageBackgroundColor"
  | "customerMessageTextColor"
  | "disclaimerButtonBackgroundColor"
  | "disclaimerButtonTextColor"
  | "launcherBackgroundColor"
  | "launcherTextColor"
  | "launcherIconColor"
  | "attentionGrabberSuggestionBubbleBackgroundColor"
  | "attentionGrabberSuggestionBubbleTextColor"
  | "attentionGrabberSuggestionBubbleHoverBackgroundColor"
  | "attentionGrabberSuggestionBubbleHoverTextColor";
