import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/hubspot.svg";

import { Separator } from "@dashboard/common/ui/separator";
import { HubspotSettingsForm } from "./HubspotSettingsForm";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        HubSpot Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Optimize your customer relationship management by integrating HubSpot
        with Octocom. This seamless integration allows you to pass unhandled
        customer queries directly to the HubSpot help desk, ensuring that your
        support team can manage customer issues efficiently and maintain high
        service standards.
      </p>
      <h3 className="text-xl font-semibold">Key Benefits:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Effortless Query Escalation:</span>{" "}
          Automatically route unhandled customer queries from Octocom to
          HubSpot, ensuring that no customer concern is left unaddressed.
        </li>
        <li>
          <span className="font-medium">Unified Support Management:</span>{" "}
          Manage all your customer interactions within Octocom, with the ability
          to escalate unresolved queries to HubSpot&apos;s help desk for
          detailed follow-up.
        </li>
        <li>
          <span className="font-medium">Enhanced Customer Support:</span>{" "}
          Improve your response times by leveraging HubSpot’s tools to handle
          complex customer queries, ensuring that each issue is resolved
          promptly and to the customer’s satisfaction.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers your support team to effectively manage
        customer interactions by combining the centralized management features
        of Octocom with HubSpot’s powerful CRM tools, resulting in improved
        customer satisfaction and streamlined support processes.
      </p>
    </div>
  );
}

export function HubspotIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        title="HubSpot"
        shortDescription="Pass unhandled customer support queries to HubSpot"
        longDescription={<Description />}
        imageSrc={icon}
        installed={installed}
        settingsForm={<HubspotSettingsForm />}
      />
    </>
  );
}
