import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDownIcon, PlusIcon } from "@radix-ui/react-icons";

import { cn } from "@dashboard/utils/ui";
import { TooltipButton } from "../TooltipButton/TooltipButton";
import { Button } from "./button";

const Accordion = AccordionPrimitive.Root;

interface AccordionItemProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> {
  variant?: string;
}

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  AccordionItemProps
>((props, forwardedRef) => {
  const { className, variant, ...restProps } = props;

  // Correctly type the ref to HTMLElement to access DOM properties like `getAttribute`
  const ref = React.useRef<HTMLElement>(null);

  const [isActive, setIsActive] = React.useState(false);

  React.useEffect(() => {
    const element = ref.current; // Now correctly inferred as HTMLElement or null

    // Define a function to check the state attribute and update `isActive`
    const checkState = () => {
      setIsActive(element?.getAttribute("data-state") === "open");
    };

    // Instantiate a MutationObserver to listen for attribute changes
    const observer = new MutationObserver(checkState);

    if (element) {
      observer.observe(element, {
        attributes: true,
        attributeFilter: ["data-state"],
      });
      // Check state initially in case the component mounts in an open state
      checkState();
    }

    // Disconnect the observer on cleanup
    return () => observer.disconnect();
  }, []);

  const conditionalClasses =
    variant === "tabs"
      ? "border border-[#0000004d] px-5 rounded-md hover:border-blue primary-border-hover duration-300 transition"
      : "";

  const activeClasses = isActive
    ? "border-blue primary-border"
    : "border-[#0000004d] group";

  return (
    <AccordionPrimitive.Item
      {...restProps}
      ref={(node) => {
        // Assign the node to the local ref
        // @ts-expect-error This is provided by shadcn UI, so we assume it works
        ref.current = node;
        // If there's a forwarded ref (function or object ref), update it as well
        if (typeof forwardedRef === "function") {
          forwardedRef(node);
        } else if (forwardedRef) {
          forwardedRef.current = node;
        }
      }}
      className={cn("border-b", conditionalClasses, activeClasses, className)}
    />
  );
});
AccordionItem.displayName = "AccordionItem";

interface AccordionTriggerProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
  // TODO: This is a bit too hacky. We should find something that is more generic?
  extraAction?: () => void;
  extraActionTooltip?: string;
}

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  // React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
  AccordionTriggerProps
>(({ className, children, extraAction, extraActionTooltip, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "group-hover:text-blue group flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all [&[data-state=open]>svg]:rotate-180",
        className,
      )}
      {...props}
    >
      {children}
      <div className="flex flex-row items-center gap-2">
        {extraAction &&
          (extraActionTooltip ? (
            <TooltipButton
              tooltipContent={extraActionTooltip}
              variant="ghost"
              buttonClassName="h-4 w-4 p-0 hover:bg-light-gray"
              onClick={(event) => {
                event.stopPropagation();
                extraAction();
              }}
            >
              <PlusIcon className="text-muted-foreground hover:text-blue h-4 w-4 shrink-0 rounded-lg opacity-0 duration-100 group-hover:opacity-100" />
            </TooltipButton>
          ) : (
            <Button
              variant="ghost"
              className="hover:bg-light-gray h-4 w-4 p-0"
              onClick={(event) => {
                event.stopPropagation();
                extraAction();
              }}
            >
              <PlusIcon className="text-muted-foreground hover:text-blue h-4 w-4 shrink-0 rounded-lg opacity-0 duration-100 group-hover:opacity-100" />
            </Button>
          ))}
        <ChevronDownIcon className="text-muted-foreground group-hover:text-blue h-4 w-4 shrink-0 transition-transform duration-200" />
      </div>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down overflow-hidden text-sm"
    {...props}
  >
    <div className={cn("pb-4 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
