import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { ConversationDetailSection } from "./ConversationDetailSection";
import { useConversation } from "../../-hooks/useConversation.hook";
import { Route } from "../..";
import { useMemo } from "react";
import { useFeedback } from "../../-hooks/useFeedback";

export function DetailsAccordion() {
  const conversation = useConversation();
  const { businesses } = Route.useRouteContext();

  const business = useMemo(
    () => businesses.find((b) => b.id === conversation.businessId),
    [businesses, conversation.businessId],
  );

  if (!business) {
    throw new Error("Business not found");
  }

  const feedback = useFeedback();

  return (
    <Accordion
      type="multiple"
      className="divide-gray-lightest w-full space-y-5 divide-y"
    >
      {conversation.topics.length > 0 && (
        <AccordionItem value="topics" className="border-b-0 !shadow-none">
          <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
            Topics
          </AccordionTrigger>
          <AccordionContent>
            <ConversationDetailSection
              details={conversation.topics.map((topic, idx) => ({
                label: `Topic ${idx + 1}:`,
                value: topic,
                copy: true,
              }))}
            />
          </AccordionContent>
        </AccordionItem>
      )}

      {feedback && (
        <AccordionItem value="feedback" className="border-b-0 !shadow-none">
          <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
            Conversation Feedback
          </AccordionTrigger>
          <AccordionContent>
            <ConversationDetailSection
              details={[
                {
                  label: "Correct:",
                  value: feedback.correctAction ? "Yes" : "No",
                },
                {
                  label: "Comment:",
                  value: feedback.comment,
                },
              ]}
            />
          </AccordionContent>
        </AccordionItem>
      )}

      <AccordionItem value="system" className="border-b-0 !shadow-none">
        <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
          System Details
        </AccordionTrigger>

        <AccordionContent>
          <ConversationDetailSection
            details={[
              {
                label: "CID:",
                value: conversation.id,
                copy: true,
              },
              {
                label: "BID:",
                value: business.id,
                copy: true,
              },
              {
                label: "OID:",
                value: business.organizationId,
                copy: true,
              },
            ]}
          />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
