import { toast } from "sonner";
import { Route } from "..";

export function useTranslateText({
  onData,
}: {
  onData: (data: string) => void;
}) {
  const { organizationSlug, publicId } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const translationMutation =
    trpc.dashboard.conversations.translateText.useMutation({
      onSuccess: (data) => {
        if (!data.success) {
          if (data.type === "INVALID_TARGET_LANG") {
            toast.error(
              "The language you are trying to translate to is not currently supported",
            );
          } else {
            toast.error(
              "The language you are trying to translate from is not currently supported",
            );
          }
        } else {
          onData(data.translatedText);
        }
      },
      onError: () => {
        toast.error("Failed to translate message");
      },
    });

  const translate = ({
    text,
    targetLang,
  }: {
    text: string;
    targetLang: string;
  }) => {
    translationMutation.mutate({
      organizationSlug,
      text,
      publicId,
      targetLang,
    });
  };

  return {
    translate,
  };
}
