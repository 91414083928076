import { Macro } from "@be/modules/macros/macros.types";
import { z } from "zod";

export const macroFormSchema = z.object({
  title: z
    .string({
      invalid_type_error: "Title is required",
    })
    .min(1, "Title is required"),
  content: z.string().nullable(),
  availableFor: z
    .string({
      invalid_type_error: "Available For is required",
    })
    .min(1, "Available For is required"),
  actions: z.array(
    z.union([
      z.object({
        type: z.literal("close"),
        data: z.null(),
      }),
      z.object({
        type: z.literal("setTag"),
        data: z.string().uuid({
          message: "Tag ID is missing",
        }),
      }),
      z.object({
        type: z.literal("assignMember"),
        data: z.string().uuid({
          message: "Assignee ID is missing",
        }),
      }),
      z.object({
        type: z.literal("snooze"),
        data: z.string(),
      }),
    ]),
  ),
});

export type MacroForm = z.infer<typeof macroFormSchema>;
export type MacroFormPayload = Omit<Macro, "id" | "organizationId">;
