import { TrackedOrder } from "@be/modules/orders/orders.types";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { format, formatDistanceToNow } from "date-fns";
import { OrderDetailSection } from "./OrderDetailSection";
import { TruncatedTooltip } from "@dashboard/common/ui/tooltip";
import { Box } from "lucide-react";
import { OrderCancelationForm } from "./OrderCancelationForm";

export function OrderDetails({ order }: { order: TrackedOrder }) {
  const availableActions = [];

  if (order.canCancel) {
    availableActions.push("cancel");
  }

  return (
    <AccordionItem
      value={order.orderId}
      className="data-[state=open]:bg-offWhite rounded-xl border-none !shadow-none"
    >
      <AccordionTrigger className="hover:bg-offWhite flex justify-between rounded-xl px-3 py-2">
        <div className="flex w-full flex-col items-start gap-1 pr-4">
          <div className="flex w-full items-center justify-between gap-4">
            <span className="text-xs font-medium">{order.orderId}</span>
            <span className="ml-auto rounded-full bg-gray-200 px-3 py-0.5 text-[10px] font-semibold text-gray-800">
              {order.status}
            </span>
          </div>
          {order.orderPlacedAt && (
            <span className="text-gray text-xs font-normal">
              (Created{" "}
              {formatDistanceToNow(new Date(order.orderPlacedAt), {
                addSuffix: true,
              })}
              )
            </span>
          )}
        </div>
      </AccordionTrigger>
      <AccordionContent className="px-3 pb-1 pt-1 text-xs">
        <OrderDetailSection
          details={[
            {
              label: "Created at:",
              value: order.orderPlacedAt
                ? format(new Date(order.orderPlacedAt), "MMM d, yyyy HH:mm")
                : "N/A",
            },
            {
              label: "Total cost:",
              value: order.totalCost
                ? `${order.totalCost.amount} ${order.totalCost.currencyCode}`
                : "N/A",
            },
          ]}
        />
        <Accordion
          type="multiple"
          className="w-full"
          defaultValue={[
            "actions",
            "shipping",
            "shipping-address",
            "billing-address",
            "fulfillments",
          ]}
        >
          {availableActions.length > 0 && (
            <AccordionItem
              value="actions"
              className="data-[state=open]:border-gray-light border-gray-light my-1 border-t !shadow-none"
            >
              <AccordionTrigger className="py-2 text-xs font-medium">
                Actions
              </AccordionTrigger>
              <AccordionContent className="pb-2 text-xs">
                <div className="flex flex-col gap-2">
                  {availableActions.includes("cancel") && (
                    <OrderCancelationForm order={order} />
                  )}
                </div>
              </AccordionContent>
            </AccordionItem>
          )}

          <AccordionItem
            value="shipping"
            className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
          >
            <AccordionTrigger className="py-2 text-xs font-medium">
              Shipping cost: {order.shippingCost?.amount ?? "N/A"}{" "}
              {order.shippingCost?.currencyCode ?? "N/A"}
            </AccordionTrigger>
            <AccordionContent className="pb-2 text-xs">
              <OrderDetailSection
                details={[
                  {
                    label: "Code:",
                    value: order.shippingMethod ?? "N/A",
                  },
                ]}
              />
            </AccordionContent>
          </AccordionItem>

          {order.recipient && (
            <AccordionItem
              value="shipping-address"
              className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
            >
              <AccordionTrigger className="py-2 text-xs font-medium">
                Shipping Address
              </AccordionTrigger>
              <AccordionContent className="pb-2 text-xs">
                <OrderDetailSection
                  details={[
                    {
                      label: "Name:",
                      value: order.recipient.name ?? "N/A",
                    },
                    {
                      label: "Address 1:",
                      value: order.recipient.address?.line1 ?? "N/A",
                    },
                    {
                      label: "Address 2:",
                      value: order.recipient.address?.line2 ?? "N/A",
                    },
                    {
                      label: "City:",
                      value: order.recipient.address?.city ?? "N/A",
                    },
                    {
                      label: "Province:",
                      value: order.recipient.address?.province ?? "N/A",
                    },
                    {
                      label: "Zip:",
                      value: order.recipient.address?.zip ?? "N/A",
                    },
                    {
                      label: "Country:",
                      value: order.recipient.address?.country ?? "N/A",
                    },
                  ]}
                />
              </AccordionContent>
            </AccordionItem>
          )}

          {order.billingInfo && (
            <AccordionItem
              value="billing-address"
              className="data-[state=open]:border-gray-light border-gray-light my-1 !shadow-none"
            >
              <AccordionTrigger className="py-2 text-xs font-medium">
                Billing Address
              </AccordionTrigger>
              <AccordionContent className="pb-2 text-xs">
                <OrderDetailSection
                  details={[
                    {
                      label: "Name:",
                      value: order.billingInfo.name ?? "N/A",
                    },
                    {
                      label: "Address 1:",
                      value: order.billingInfo.address?.line1 ?? "N/A",
                    },
                    {
                      label: "Address 2:",
                      value: order.billingInfo.address?.line2 ?? "N/A",
                    },
                    {
                      label: "City:",
                      value: order.billingInfo.address?.city ?? "N/A",
                    },
                    {
                      label: "Province:",
                      value: order.billingInfo.address?.province ?? "N/A",
                    },
                    {
                      label: "Zip:",
                      value: order.billingInfo.address?.zip ?? "N/A",
                    },
                    {
                      label: "Country:",
                      value: order.billingInfo.address?.country ?? "N/A",
                    },
                  ]}
                />
              </AccordionContent>
            </AccordionItem>
          )}

          <AccordionItem
            value="fulfillments"
            className="my-1 border-none !shadow-none"
          >
            <AccordionTrigger className="py-2 text-xs font-medium">
              Fulfillments ({order.fulfillments.length})
            </AccordionTrigger>
            <AccordionContent className="pb-2 text-xs">
              {order.fulfillments.map((fulfillment, index) => (
                <div
                  key={index}
                  className={
                    index > 0 ? "mt-2 border-t border-gray-200 pt-2" : ""
                  }
                >
                  <OrderDetailSection
                    details={[
                      {
                        label: "External ID:",
                        value: fulfillment.externalId ?? "N/A",
                      },
                      {
                        label: "Status:",
                        value: fulfillment.status,
                      },
                      {
                        label: "Tracking:",
                        value: fulfillment.trackingUrl ? (
                          <a
                            href={fulfillment.trackingUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                          >
                            Track package
                          </a>
                        ) : (
                          "N/A"
                        ),
                      },
                    ]}
                  />
                  {fulfillment.items && fulfillment.items.length > 0 && (
                    <div>
                      <div className="flex items-center justify-between py-1">
                        <span className="text-xs text-gray-500">
                          Items ({fulfillment.items.length})
                        </span>
                      </div>
                      <div className="flex flex-col gap-1">
                        {fulfillment.items.map((item, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between text-xs"
                          >
                            <div className="flex w-[45%] items-center pr-2.5">
                              <div className="mr-2 h-6 w-6 flex-shrink-0 overflow-hidden rounded-xl">
                                {item.image ? (
                                  <img
                                    src={item.image}
                                    alt={item.name}
                                    className="h-full w-full object-cover"
                                    onError={(e) => {
                                      e.currentTarget.style.display = "none";
                                      const nextElement = e.currentTarget
                                        .nextElementSibling as HTMLElement;
                                      nextElement.style.display = "block";
                                    }}
                                  />
                                ) : null}
                                <Box
                                  className="h-full w-full"
                                  strokeWidth={1}
                                  style={{
                                    display: item.image ? "none" : "block",
                                  }}
                                />
                              </div>
                              <TruncatedTooltip
                                className="w-full text-left text-black"
                                text={item.name}
                              />
                            </div>
                            <div className="p-1 text-right">
                              <p>
                                {item.price?.currencyCode}
                                {item.price?.amount} x {item.quantity}{" "}
                                {item.price?.currencyCode}
                                {(
                                  parseFloat(item.price?.amount ?? "0") *
                                  (item.quantity ?? 0)
                                ).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </AccordionContent>
    </AccordionItem>
  );
}
