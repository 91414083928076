import { Button } from "@dashboard/common/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@dashboard/common/ui/dialog";
import { Separator } from "@dashboard/common/ui/separator";
import { StaticActionComponent } from "./Actions/StaticActionComponent";
import { MacroAction } from "@be/modules/macros/macros.types";

interface MacroDialogProps {
  title: string;
  content: string | null;
  actions: MacroAction[];
  availableForValue: string;
}

export function MacroDialog({
  title,
  content,
  actions,
  availableForValue,
}: MacroDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="pl-0" variant="link">
          {title}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] overflow-scroll">
        <DialogHeader>
          <DialogTitle className="mx-auto">{title}</DialogTitle>
        </DialogHeader>

        {content && (
          <>
            <div className="flex flex-col items-start justify-center gap-4 rounded bg-gray-50 p-4">
              <div>{content}</div>
            </div>

            <Separator />
          </>
        )}

        {actions.length > 0 && (
          <>
            <div className="flex flex-col items-start justify-start gap-2">
              {actions.map((action, index) => (
                <StaticActionComponent key={index} action={action} />
              ))}
            </div>

            <Separator />
          </>
        )}

        <div className="flex items-center justify-start gap-2">
          Available for:
          <span className="font-medium">{availableForValue}</span>
        </div>
      </DialogContent>
    </Dialog>
  );
}
