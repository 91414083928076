import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { useConversation } from "../../-hooks/useConversation.hook";
import { Separator } from "@dashboard/common/ui/separator";
import { RecentConversationLink } from "./RecentConversationLink";

export function RecentConversations() {
  const { relatedConversations } = useConversation();

  if (relatedConversations.length < 1) {
    return null;
  }

  const shownConversations = relatedConversations.slice(0, 20);

  return (
    <Accordion type="multiple" className="w-full">
      <AccordionItem value="item-0" className="border-none !shadow-none">
        <AccordionTrigger className="pb-0 pl-7 pr-4 pt-5 text-xs font-medium uppercase">
          Recent Conversations
        </AccordionTrigger>
        <AccordionContent className="mt-5 flex max-h-96 flex-col gap-1 overflow-y-scroll pb-0 pl-4 pr-4">
          {shownConversations.map((c, index) => (
            <>
              <RecentConversationLink
                key={c.publicId}
                publicId={c.publicId}
                isClosed={c.isClosed}
                createdAt={new Date(c.createdAt)}
                channel={c.latestChannel}
                orderId={c.orderId}
              />
              {index !== shownConversations.length - 1 && (
                <Separator className="bg-offWhite mx-3 w-auto" />
              )}
            </>
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
