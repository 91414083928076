import { createFileRoute } from "@tanstack/react-router";
import { IntegrationServiceName } from "@be/modules/integrations/integrations.types";

import { TelegramIntegrationDialog } from "./-components/TelegramIntegrationDialog";
import { FacebookIntegrationDialog } from "./-components/FacebookIntegrationDialog";
import { WhatsappIntegrationDialog } from "./-components/WhatsappIntegrationDialog";
import { InstagramIntegrationDialog } from "./-components/InstagramIntegrationDialog";
import { ShopifyIntegrationDialog } from "./-components/ShopifyIntegrationDialog";
import { WixIntegrationDialog } from "./-components/WixIntegrationDialog";
import { GorgiasIntegrationDialog } from "./-components/GorgiasIntegrationDialog";
import { ZendeskIntegrationDialog } from "./-components/ZendeskIntegrationDialog";
import { EmailIntegrationDialog } from "./-components/EmailIntegrationDialog";
import { GoogleIntegrationDialog } from "./-components/GoogleIntegrationDialog";
import { OutlookIntegrationDialog } from "./-components/OutlookIntegrationDialog";
import { DixaIntegrationDialog } from "./-components/DixaIntegrationDialog";
import { HubspotIntegrationDialog } from "./-components/HubspotIntegrationDialog";
import { RichpanelIntegrationDialog } from "./-components/RichpanelIntegrationDialog";
import { KustomerIntegrationDialog } from "./-components/KustomerIntegrationDialog";
import { ReamazeIntegrationDialog } from "./-components/ReamazeIntegrationDialog";
import { LiveAgentIntegrationDialog } from "./-components/LiveAgentIntegrationDialog";
import { FreshdeskIntegrationDialog } from "./-components/FreshdeskIntegrationDialog";
import { KlaviyoIntegrationDialog } from "./-components/KlaviyoIntegrationDialog";
import { BrazeIntegrationDialog } from "./-components/BrazeIntegrationDialog";
import { NetoHqIntegrationDialog } from "./-components/NetoHqIntegrationDialog";
import { AdobeCommerceIntegrationDialog } from "./-components/AdobeCommerceIntegrationDialog";
import { BitrixIntegrationDialog } from "./-components/BitrixIntegrationDialog";
import { PrestaIntegrationDialog } from "./-components/PrestaIntegrationDialog";
import { BigCommerceIntegrationDialog } from "./-components/BigCommerceIntegrationDialog";
import { WooCommerceIntegrationDialog } from "./-components/WooCommerceIntegrationDialog";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/integrations/",
)({
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    const integrations: IntegrationServiceName[] = [
      "telegram",
      "smtpImap",
      "dixa",
      "richpanel",
      "hubspot",
      "klaviyo",
      "braze",
    ];

    await Promise.all([
      trpcClientUtils.dashboard.integrations.getInstalledIntegrations.fetch({
        businessSlug,
      }),
      ...integrations.map((integrationServiceName) =>
        trpcClientUtils.dashboard.integrations.getIntegration.fetch({
          businessSlug,
          integrationServiceName,
        }),
      ),
    ]);
  },

  component: IntegrationsPage,
});

function IntegrationsPage() {
  const { businessSlug } = Route.useParams();
  const { trpc } = Route.useRouteContext();

  const [installedIntegrations] =
    trpc.dashboard.integrations.getInstalledIntegrations.useSuspenseQuery({
      businessSlug,
    });

  return (
    <div className="flex h-screen flex-col">
      <div className="flex h-screen flex-col">
        <div className="border-gray-light w-full border-b bg-white px-10 py-4">
          <h1 className="text-xl font-medium text-black">Integrations</h1>
        </div>
        <div className="grid w-full grid-cols-4 gap-4 p-10">
          <TelegramIntegrationDialog
            installed={installedIntegrations.includes("telegram")}
          />

          <FacebookIntegrationDialog
            installed={installedIntegrations.includes("messenger")}
          />

          <WhatsappIntegrationDialog
            installed={installedIntegrations.includes("whatsapp")}
          />

          <InstagramIntegrationDialog
            installed={installedIntegrations.includes("instagram")}
          />

          <EmailIntegrationDialog
            installed={installedIntegrations.includes("smtpImap")}
          />

          <OutlookIntegrationDialog
            installed={installedIntegrations.includes("outlook")}
          />

          <GoogleIntegrationDialog
            installed={installedIntegrations.includes("google")}
          />

          <ShopifyIntegrationDialog
            installed={installedIntegrations.includes("shopify")}
          />

          <NetoHqIntegrationDialog installed={false} />

          <BigCommerceIntegrationDialog installed={false} />

          <AdobeCommerceIntegrationDialog installed={false} />

          <PrestaIntegrationDialog installed={false} />

          <BitrixIntegrationDialog installed={false} />

          <WooCommerceIntegrationDialog installed={false} />

          <WixIntegrationDialog
            installed={installedIntegrations.includes("wix")}
          />

          <GorgiasIntegrationDialog
            installed={installedIntegrations.includes("gorgias")}
          />

          <ZendeskIntegrationDialog
            installed={installedIntegrations.includes("zendesk")}
          />

          <DixaIntegrationDialog
            installed={installedIntegrations.includes("dixa")}
          />

          <HubspotIntegrationDialog
            installed={installedIntegrations.includes("hubspot")}
          />

          <RichpanelIntegrationDialog
            installed={installedIntegrations.includes("richpanel")}
          />

          <KustomerIntegrationDialog installed={false} />

          <ReamazeIntegrationDialog installed={false} />

          <LiveAgentIntegrationDialog installed={false} />

          <FreshdeskIntegrationDialog installed={false} />

          <KlaviyoIntegrationDialog
            installed={installedIntegrations.includes("klaviyo")}
          />

          <BrazeIntegrationDialog
            installed={installedIntegrations.includes("braze")}
          />
        </div>
      </div>
    </div>
  );
}
