import { IntegrationBlock } from "./IntegrationBlock";
import googleLogo from "@dashboard/assets/google-logo.svg";
import googleLoginButton from "@dashboard/assets/google-login-button.png";
import { Separator } from "@dashboard/common/ui/separator";
import { env } from "@dashboard/env";
import { Route } from "..";
import { useRemoveIntegration } from "../-hooks/useRemoveIntegration";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Google Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Automate your customer support emails by integrating Google workspace
        with Octocom
      </p>
      <h3 className="text-xl font-semibold">Key Features:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Smooth Integration:</span> Effortlessly
          link your Google account to Octocom, enabling centralized management
          of your support emails and customer interactions from a single
          platform.
        </li>
        <li>
          <span className="font-medium">Automated Email Handling:</span> Utilize
          Octocom&apos;s automation capabilities to manage your Google emails
          more efficiently.
        </li>
        <li>
          <span className="font-medium">Unified Support Dashboard:</span> Manage
          all your Gmail emails within the Octocom dashboard. This unified view
          allows your team to respond to customer inquiries across different
          channels without switching between platforms, streamlining your
          support operations.
        </li>
        <li>
          <span className="font-medium">Enhanced Customer Communication:</span>{" "}
          Leverage the power of Octocom to provide personalized responses and
          proactive support through Google mail. Improve customer satisfaction
          by ensuring that every interaction is timely, relevant, and handled
          with care.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers your support team to manage Google Emails more
        efficiently, combining automation and centralized management tools to
        enhance your customer service operations and drive better outcomes.
      </p>
    </div>
  );
}

export function GoogleIntegrationDialog({ installed }: { installed: boolean }) {
  const { businessSlug } = Route.useParams();

  const url = new URL("https://accounts.google.com/o/oauth2/v2/auth");

  const params = new URLSearchParams({
    client_id: env.DASHBOARD_GOOGLE_CLIENT_ID,
    redirect_uri: `${env.DASHBOARD_BASE_API_URL}/google/callback`,
    response_type: "code",
    state: businessSlug,
    scope: "https://www.googleapis.com/auth/gmail.modify",
    access_type: "offline",
    prompt: "consent",
  });

  url.search = params.toString();

  const removeIntegration = useRemoveIntegration();

  return (
    <>
      <IntegrationBlock
        title="Google"
        shortDescription="Send and receive Emails from your Google workspace account."
        longDescription={<Description />}
        imageSrc={googleLogo}
        installed={installed}
        onRemove={() => removeIntegration({ integrationServiceName: "google" })}
        customButton={
          <img
            className="w-48 max-w-48 cursor-pointer"
            src={googleLoginButton}
            onClick={() => {
              window.location.href = url.toString();
            }}
          />
        }
      />
    </>
  );
}
