import { Route } from "..";
import { toast } from "sonner";
import { AccountSettingsForm } from "../-utils/accountSettingsFormSchema";
import { Dispatch, SetStateAction } from "react";

export function useSubmitForm({
  setSubmitCount,
}: {
  setSubmitCount: Dispatch<SetStateAction<number>>;
}) {
  const { trpc, user } = Route.useRouteContext();
  const { organizationSlug } = Route.useParams();

  const trpcUtils = trpc.useUtils();

  const updateMutation = trpc.dashboard.users.update.useMutation({
    onSuccess: async () => {
      await trpcUtils.dashboard.users.getCurrent.invalidate();

      setSubmitCount((count) => count + 1);

      toast.success("Successfully updated your account settings.");
    },
    onError: () => {
      toast.error("Failed to update your account settings. Please try again.");
    },
  });

  return {
    onSubmit: (data: AccountSettingsForm) => {
      updateMutation.mutate({
        organizationSlug,
        data: {
          id: user.id,
          ...data,
          notificationSoundLevel: data.notificationSoundLevel[0],
        },
      });
    },

    onError: (errors: unknown) => {
      console.error(errors);
      toast.error("Failed to update your account settings.");
    },
  };
}
