import { MacroForm, MacroFormPayload } from "./macroFormSchema";

export function formatPayload(data: MacroForm): MacroFormPayload {
  let availableFor: string | null;
  if (data.availableFor === "everyone") {
    availableFor = null;
  } else {
    availableFor = data.availableFor;
  }

  return {
    ...data,
    availableFor,
  };
}
