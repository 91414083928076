import { IntegrationBlock } from "./IntegrationBlock";
import icon from "@dashboard/assets/freshdesk.svg";

import { Separator } from "@dashboard/common/ui/separator";

function Description() {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="text-2xl font-semibold">
        Freshdesk Integration with Octocom
      </h2>
      <p className="text-justify text-gray-800">
        Enhance your customer support operations by integrating Freshdesk with
        Octocom. This seamless integration allows you to pass unhandled customer
        queries directly to the Freshdesk help desk, ensuring that your support
        team can manage and resolve issues effectively across all customer
        communication channels.
      </p>
      <h3 className="text-xl font-semibold">Key Benefits:</h3>
      <ul className="space-y-2 text-justify text-gray-800">
        <li>
          <span className="font-medium">Smooth Query Escalation:</span>{" "}
          Automatically route unhandled customer queries from Octocom to
          Freshdesk, ensuring that every issue receives timely attention and
          resolution.
        </li>
        <li>
          <span className="font-medium">Centralized Support Management:</span>{" "}
          Manage all your customer interactions through Octocom, with the
          flexibility to escalate unresolved queries to Freshdesk for detailed
          follow-up.
        </li>
        <li>
          <span className="font-medium">
            Comprehensive Multi-Channel Support:
          </span>{" "}
          Leverage Freshdesk’s powerful multi-channel support tools to respond
          to customer queries across various platforms, ensuring quick and
          accurate assistance that enhances customer satisfaction.
        </li>
      </ul>
      <Separator />
      <p className="text-justify">
        This integration empowers your support team to deliver exceptional
        customer service by combining Octocom’s centralized management features
        with Freshdesk’s robust multi-channel support capabilities, leading to
        quicker issue resolution and higher customer satisfaction.
      </p>
    </div>
  );
}

export function FreshdeskIntegrationDialog({
  installed,
}: {
  installed: boolean;
}) {
  return (
    <>
      <IntegrationBlock
        title="Freshdesk"
        shortDescription="Pass unhandled customer support queries to Freshdesk"
        longDescription={<Description />}
        imageSrc={icon}
        manualInstallation={true}
        installed={installed}
      />
    </>
  );
}
