import { useFormContext } from "react-hook-form";
import { formDefaults } from "../../-utils/formDefaults";
import { WebChatConfigForm } from "../../-utils/formSchema";

function TopBar() {
  const { watch } = useFormContext<WebChatConfigForm>();
  const topBarTitle = watch("topBarTitle");
  const topBarBackgroundColor =
    watch("topBarBackgroundColor") ?? formDefaults.topBarBackgroundColor;
  const topBarTextColor =
    watch("topBarTextColor") ?? formDefaults.topBarTextColor;
  const topBarIconColor =
    watch("topBarIconColor") ?? formDefaults.topBarIconColor;
  const enableTopBarBorder = watch("enableTopBarBorder");
  const topBarImageSrc = watch("topBarImageSrc");

  return (
    <div
      style={{ backgroundColor: topBarBackgroundColor }}
      className={`flex h-[65px] items-center justify-between rounded-t-xl border-[#0000001a] px-6 py-4 ${
        enableTopBarBorder ? "border-b" : ""
      }`}
    >
      <div className="flex flex-row items-center gap-3">
        <div>
          <img
            className="max-w-250 mx-auto my-auto h-8 rounded"
            src={topBarImageSrc ?? formDefaults.topBarImageSrc}
            alt="Top Bar Image"
          />
        </div>
        {(!!topBarTitle || !topBarImageSrc) && (
          <div className="flex h-fit flex-col">
            <h5
              style={{ color: topBarTextColor }}
              className="font-semibold leading-none"
            >
              {topBarTitle ?? formDefaults.topBarTitle}
            </h5>
          </div>
        )}
      </div>
      <div className="cursor-pointer rounded-lg hover:bg-gray-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill={topBarIconColor}
          aria-hidden="true"
          className="h-7 w-7 text-gray-800"
        >
          <path
            fillRule="evenodd"
            d="M10.5 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}

export default TopBar;
