import { z } from "zod";

export const filterKeysSchema = z.enum([
  "timeRange",
  "businessId",
  "assignee",
  "language",
  "channel",
  "handedOff",
]);

export const filtersSchema = z.object({
  timeRange: z
    .union([
      z.literal("today"),
      z.literal("this-week"),
      z.literal("this-month"),
    ])
    .nullish(),
  channel: z
    .union([
      z.literal("email"),
      z.literal("web"),
      z.literal("instagram"),
      z.literal("instagramComment"),
      z.literal("messenger"),
      z.literal("facebookComment"),
    ])
    .nullish(),
  handedOff: z.union([z.literal("yes"), z.literal("no")]).nullish(),
  language: z.string().nullish(),
  assignee: z.string().nullish(),
  businessId: z.string().nullish(),
});

export const customViewSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  title: z.string(),
  filters: filtersSchema,
});

export const customViewUpdateSchema = customViewSchema
  .omit({
    organizationId: true,
  })
  .partial()
  .required({
    id: true,
  });
