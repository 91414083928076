import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@dashboard/common/ui/accordion";
import { SidebarButton } from "@dashboard/common/Sidebar/SidebarButton";
import { SidebarButtonExternal } from "@dashboard/common/Sidebar/SidebarButtonExternal";
import { Route } from "../_config-sidebar";

export function ConfigurationAccordion() {
  const { businessSlug } = Route.useParams();
  const { businesses } = Route.useRouteContext();

  const business = businesses.find((b) => b.slug === businessSlug);

  return (
    <Accordion type="multiple" className="w-full">
      <AccordionItem value="ai" className="border-b-0 !shadow-none">
        <AccordionTrigger className="hover:bg-gray-light rounded-lg px-[12px] py-2">
          <span className="text-sm font-semibold text-black">
            AI & Automation
          </span>
        </AccordionTrigger>
        <AccordionContent className="pb-0">
          <SidebarButton
            text="Knowledge"
            to="/organization/$organizationSlug/settings/$businessSlug/knowledge"
          />
          <SidebarButton
            text="Product Search"
            to="/organization/$organizationSlug/settings/$businessSlug/product-search"
          />
          {business?.enableFlows && (
            <SidebarButton
              text="Flows"
              to="/organization/$organizationSlug/settings/$businessSlug/flows"
            />
          )}
          <SidebarButton
            text="Feedback"
            to="/organization/$organizationSlug/settings/$businessSlug/feedback"
          />
          <SidebarButtonExternal
            text="Bot Playground"
            to={`https://www.octocom.ai/try?bot-name=${businessSlug}`}
          />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="channels" className="border-b-0 !shadow-none">
        <AccordionTrigger className="hover:bg-gray-light rounded-lg px-[12px] py-2">
          <span className="text-sm font-semibold text-black">Channels</span>
        </AccordionTrigger>
        <AccordionContent className="pb-0">
          <SidebarButton
            text="Web Chat"
            to="/organization/$organizationSlug/settings/$businessSlug/web-chat"
          />
          <SidebarButton
            text="Email"
            to="/organization/$organizationSlug/settings/$businessSlug/email"
          />
        </AccordionContent>
      </AccordionItem>
      {businessSlug === "woo-test" && (
        <AccordionItem value="integrations" className="border-b-0 !shadow-none">
          <AccordionTrigger className="hover:bg-gray-light rounded-lg px-[12px] py-2">
            <span className="text-sm font-semibold text-black">
              Integrations
            </span>
          </AccordionTrigger>
          <AccordionContent className="pb-0">
            <SidebarButton
              text="WooCommerce"
              to="/organization/$organizationSlug/settings/$businessSlug/woocommerce"
            />
          </AccordionContent>
        </AccordionItem>
      )}
    </Accordion>
  );
}
