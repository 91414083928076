import { Route } from "..";
import { toast } from "sonner";

export type ShopifySubscription = "Basic" | "Advanced" | "Enterprise";

export function useSubscribe() {
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();

  const subscribeMutation = trpc.dashboard.shopify.subscribe.useMutation({
    onSuccess: (data) => {
      if (data) {
        toast.success(
          "Subscribed successfully, you will be redirected to accept your subscription in 5 seconds.",
        );
        setTimeout(() => {
          window.location.href = data;
        }, 5000);
      } else {
        toast.error(
          "Something went wrong while subscribing please try again later",
        );
      }
    },
    onError: (error: unknown) => {
      console.error("Error subscribing", error);
      toast.error(
        "Something went wrong while subscribing please try again later",
      );
    },
  });

  const subscribe = ({
    subscriptionType,
  }: {
    subscriptionType: ShopifySubscription;
  }) => {
    subscribeMutation.mutate({
      businessSlug,
      subscriptionType,
    });
  };

  return subscribe;
}
