import { Link, createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { flowTemplates } from "../-utils/flowTemplates";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@dashboard/common/ui/form";
import { Input } from "@dashboard/common/ui/input";
import { Textarea } from "@dashboard/common/ui/textarea";
import { Button } from "@dashboard/common/ui/button";
import { Switch } from "@dashboard/common/ui/switch";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSubmitForm } from "./-hooks/useSubmitForm";
import { FlowForm, flowFormSchema } from "../-utils/flowFormSchema";
import { Separator } from "@dashboard/common/ui/separator";

export const Route = createFileRoute(
  "/organization/$organizationSlug/_navbar/settings/$businessSlug/_config-sidebar/flows/new/",
)({
  validateSearch: z.object({
    template: z.string().optional(),
  }),
  loader: async ({
    context: { trpcClientUtils },
    params: { businessSlug },
  }) => {
    await trpcClientUtils.dashboard.flows.getAllActions.ensureData({
      businessSlug,
    });
  },
  component: NewFlowPage,
});

function NewFlowPage() {
  const { organizationSlug, businessSlug } = Route.useParams();
  const { template } = Route.useSearch();
  const { trpc } = Route.useRouteContext();

  const [actions] = trpc.dashboard.flows.getAllActions.useSuspenseQuery({
    businessSlug,
  });

  let defaultValues: FlowForm = {
    title: "",
    whenToFollow: "",
    isLive: true,
    orderIdRequired: false,
    variants: [],
  };

  const flowTemplatesKeys = Object.keys(flowTemplates);

  if (template && flowTemplatesKeys.includes(template)) {
    const selectedTemplate =
      flowTemplates[template as keyof typeof flowTemplates];

    defaultValues = {
      title: selectedTemplate.title,
      whenToFollow: selectedTemplate.whenToFollow,
      isLive: true,
      orderIdRequired: selectedTemplate.orderIdRequired,
      variants: [],
    };
  }

  const form = useForm<FlowForm>({
    resolver: zodResolver(flowFormSchema),
    defaultValues,
  });

  const { onSubmit, onError } = useSubmitForm();

  return (
    <Form {...form}>
      <form onSubmit={(e) => void form.handleSubmit(onSubmit, onError)(e)}>
        <div className="relative py-0">
          <div className="border-gray-light flex w-full justify-between border-b px-10 py-4">
            <h1 className="text-xl font-medium text-black">
              Creata a new flow
            </h1>
            <div className="flex items-center gap-2">
              <Link
                to="/organization/$organizationSlug/settings/$businessSlug/flows"
                params={{ organizationSlug, businessSlug }}
              >
                <Button
                  variant="secondary"
                  className="flex items-center"
                  type="button"
                >
                  Back to flows
                </Button>
              </Link>

              <Button
                variant="publish"
                className="flex items-center"
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>

          <div className="flex gap-10 px-10 py-5">
            <div className="flex w-full max-w-xl flex-col gap-5">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Flow Title</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormDescription>
                      Give a unique and descriptive title to the flow, e.g.
                      &quot;Order Tracking&quot;, &quot;Quality
                      Complaints&quot;, etc.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="whenToFollow"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>When should this flow be followed?</FormLabel>
                    <FormControl>
                      <Textarea rows={2} {...field} />
                    </FormControl>
                    <FormDescription>
                      Provide a description of when this flow should be
                      followed. You may provide example scenarios and customer
                      inquiries.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="isLive"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel>Live Flow</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="orderIdRequired"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-start gap-4 space-y-0">
                    <FormLabel>Order ID Required</FormLabel>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="border-gray-light flex max-w-96 flex-col items-start gap-4 rounded-lg border p-6">
              <p>
                The bot can carry out a large number of actions during the flow,
                to tell the bot that it should carry out an action include this
                instruction:
              </p>
              <p>
                call the <span className="font-medium">`botAction`</span>{" "}
                function with <span className="font-medium">argument1</span>,{" "}
                <span className="font-medium">argument2</span>... as the
                arguments
              </p>
              <Separator />
              <p className="font-medium">Available actions:</p>
              <ul>
                {actions.map((action) => (
                  <li
                    className="bg-accent m-1 inline-block rounded-lg p-1 px-2 text-sm font-medium"
                    key={action}
                  >
                    {action}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}
